import TeamSlider from "../components/TeamSlider";
import sam from "../assets/images/team/sam.jpg";

function TeamProfile12() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={sam}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Sampson Kofi Mawuko Kudior
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              COMMUNITY ENGAGEMENT & OUTREACH OFFICER
            </h5>
            <p className="mb-5 text-justify">
              Sampson Kofi Mawuko Kudior, is currently pursuing a BSc in Marine
              Science degree at the Department of Marine and Fisheries Sciences,
              University of Ghana. Sampson&apos;s fascination with the ocean and
              its ecosystems has motivated him to engage with coastal
              communities on the conservation of the ocean and its resources.
              Through his engagements with communities and pragmatic actions, he
              has helped to reduce pollution to the ocean mainly from plastics.
              He has conducted outreach programs in fishing communities on sea
              turtle conservation and beach pollution. He is currently
              researching the impact of climate change on coastal communities
              and hopes to explore practical solutions for addressing some
              impact on the ocean and livelihoods. As an active member of the
              University of Ghana Environmental Club, he continues to work with
              different stakeholders to promote sustainability and raise
              awareness about critical marine issues.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile12;
