import logo from "../assets/images/whro.png";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="bg-green-900 text-white">
        <div className="container py-24 ll:px-10">
          <div className="flex flex-wrap gap-y-10 xl:gap-y-0">
            <div className="md:px-10 w-full md:w-2/4 xl:w-[30%]">
              <h6 className="text-footer-white mini-heading footer-text lg:pr-32 text-3xl">
                LET&apos;S HELP SAVE OUR EARTH.
              </h6>
              <p className="mt-10 text-footer-white">
                Let&apos;s Transform The World Together. And Live Life Cleaner
                By Making Earth Greener.
              </p>
            </div>
            <div className="md:px-10 w-full md:w-2/4 xl:w-[20%]">
              <div className=" flex flex-col gap-y-10 xl:gap-y-16">
                <div className="w-full">
                  <h6 className="text-footer-white font-bold mb-2">
                    Quick Links
                  </h6>
                  <Link to="/">
                    <p className="text-footer-white">Home</p>
                  </Link>
                  <Link to="/about">
                    <p className="text-footer-white">About</p>
                  </Link>
                  <Link to="/services">
                    <p className="text-footer-white">Services</p>
                  </Link>
                  <Link to="/projects">
                    <p className="text-footer-white">Projects</p>
                  </Link>
                  <Link to="/programmes">
                    <p className="text-footer-white">Programmes</p>
                  </Link>
                  <Link to="/blogs">
                    <p className="text-footer-white">News</p>
                  </Link>
                  <Link to="/publications-list">
                    <p className="text-footer-white">Publications</p>
                  </Link>
                  <Link to="/gallery-list">
                    <p className="text-footer-white">Gallery</p>
                  </Link>
                  <Link to="/volunteer">
                    <p className="text-footer-white">Volunteer</p>
                  </Link>
                  <Link to="/coming-soon">
                    <p className="text-footer-white">Donate</p>
                  </Link>
                </div>
                {/* <div className="w-full">
                  <h6 className="text-footer-white font-bold mb-2">
                    Get involved
                  </h6>
                  <Link to="/contact">
                    <p className="text-footer-white">Contact Us</p>
                  </Link>
                  <Link to="/volunteer">
                    <p className="text-footer-white">Volunteer</p>
                  </Link>
                  <Link to="/">
                    <p className="text-footer-white">Donate</p>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="md:px-10 w-full md:w-2/4 xl:w-[20%] flex flex-col gap-y-10 xl:gap-y-16">
              <div className="w-full">
                <h6 className="text-footer-white font-bold mb-2">Address</h6>
                <Link to="/">
                  <p className="text-footer-white">8th Ali-Ago Street</p>
                </Link>
                <Link to="/">
                  <p className="text-footer-white">East - Legon</p>
                </Link>
                <Link to="/">
                  <p className="text-footer-white">P.O Box LG 485, Legon</p>
                </Link>
              </div>
              <div className="w-full">
                <h6 className="text-footer-white font-bold mb-2">Contact</h6>
                <Link to="/">
                  <p className="text-footer-white">+233 30 255 4732</p>
                </Link>
                <Link to="/">
                  <p className="text-footer-white">+233 53 513 7979</p>
                </Link>
                <Link to="/">
                  <p className="text-footer-white">info@whroghana.org</p>
                </Link>
              </div>
            </div>
            <div className="md:px-10 w-full md:w-2/4 xl:w-[30%]">
              <div className="w-full">
                <h6 className="text-footer-white font-bold mb-6">
                  Join our Newsletter
                </h6>
                <div className="border border-footer-white rounded-3xl p-2 pl-4 flex w-4/5">
                  <input
                    type="text"
                    placeholder="EMAIL"
                    className="bg-transparent w-[80%] outline-none placeholder:text-slate-200 text-footer-white"
                  />
                  <button className="text-footer-white">SEND</button>
                </div>
              </div>
              <div className="w-full">
                <img className="w-40 mt-8" alt="WHRO Logo" src={logo} />
                <div className="flex pt-4 gap-x-5 text-footer-white">
                  <a href="https://www.facebook.com/whroghana/" target="_blank">
                    <img alt="facebook icon" className="h-5" src={facebook} />
                  </a>
                  <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                    <img alt="twitter icon" className="h-5" src={twitter} />
                  </a>
                  <a
                    href="https://www.instagram.com/whroghana/"
                    target="_blank"
                  >
                    <img alt="instagram icon" className="h-5" src={instagram} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
