import sthumb3 from "../assets/images/blog3.jpg";
import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";
import thumb1 from "../assets/images/conference.jpg";
import thumb2 from "../assets/images/blog2.jpeg";
import thumb3 from "../assets/images/beach_cleanup/4.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import MetaTags from "../components/MetaTags";
import Header from "../components/Header";
import header from "../assets/images/news.jpg";

function BlogList() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog"
        canonical="https://www.whroghana.org/blogs"
      />
      <Header
        img={header}
        subHeading="OUR EVENTS & NEWS"
        mainHeading="Find Out All the Latest"
      />
      <div className="py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div
              data-aos="fade-right"
              data-aos-delay="200"
              className="w-full box-border flex-wrap flex flex-col md:flex-row gap-y-10 lg:gap-y-10"
            >
              <div className="basis-full box-border md:basis-1/2 xl:basis-1/4 md:px-5">
                <Link to={"/blog7"}>
                  <img src={thumb3} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2024</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    WHRO Celebrates World Oceans Day 2024 with Awareness
                    Creation and Beach Cleanup
                  </h4>
                </Link>
              </div>
              <div className="basis-full box-border md:basis-1/2 xl:basis-1/4 md:px-5">
                <Link to={"/blog"}>
                  <img src={thumb1} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      EVENT
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    2nd Ghana National Sea Turtle Conference 2023
                  </h4>
                </Link>
              </div>
              <div className="basis-full box-border md:basis-1/2 xl:basis-1/4 md:px-5">
                <Link to={"/blog4"}>
                  <img src={sthumb4} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                    Turtles
                  </h4>
                </Link>
              </div>
              <div className="basis-full box-border md:basis-1/2 xl:basis-1/4 md:px-5">
                <Link to={"/blog3"}>
                  <img src={sthumb3} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    Experts Discuss Urgent Need for Sea Turtle Conservation in
                    Ghana
                  </h4>
                </Link>
              </div>
              <div className="basis-full box-border md:basis-1/2 xl:basis-1/4 md:px-5">
                <Link to={"/blog2"}>
                  <img src={thumb2} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                    Resources Conservation’
                  </h4>
                </Link>
              </div>
              <div className="basis-full box-border md:basis-1/2 xl:basis-1/4 md:px-5">
                <Link to={"/blog5"}>
                  <img src={sthumb5} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s Visit
                    to Anyanui Mangrove Market
                  </h4>
                </Link>
              </div>
            </div>
            {/* <div
              data-aos="fade-left"
              data-aos-delay="200"
              className="w-0 xl:w-1/4 md:px-5"
            >
              <img className="w-full" src={ad} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogList;
