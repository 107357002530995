import TeamSlider from "../components/TeamSlider";
import jannah from "../assets/images/team/jannah.jpg";

function TeamProfile9() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={jannah}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Jannatul Firdaus
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              PROGRAMME COORDINATOR
            </h5>
            <p className="mb-5 text-justify">
              Jannatul-Firdaus Alhussein holds a BSc degree in Marine Science
              from the Department of Marine and Fisheries Sciences, University
              of Ghana. She has extensive knowledge in natural resource
              conservation with expertise in sea turtle conservation and climate
              change. Her current research is looking into the use of sea
              turtles as bioindicators of ocean health. During her time in
              school, she was a member of the student leadership of Department
              of Marine and Fisheries Sciences, University of Ghana where gained
              valuable skill in mobilization and youth engagement.
            </p>
            <p className="mb-5 text-justify">
              She has worked with the Department of Marine and Fisheries Science
              as Intern and National Service Person during which time she gained
              experience in designing research, data collection, data analysis
              and report writing. She volunteered with the Ghana Turtle
              Conservation Project as field personnel and gained experience in
              turtle conservation, conservation education and awareness,
              community/stakeholder engagement and advocacy. She is currently
              the Coordinator for the Young Scientist Programme. She is highly
              motivated and has been involved in building the capacity of youth
              and school children in natural resources conservation through
              research, training, information sharing, and awareness creation.
              Her active involvement with school children has led to the most
              schools in coastal communities engaging in sanitation, natural
              resources conservation and protection.
            </p>
            <p className="mb-5 text-justify">
              Jannah has attended several national and international workshops
              and conferences and is currently a member of the International Sea
              Turtle Society (ISTS) and the Association for the Science of
              Limnology and Oceanography (ASLO).
            </p>
            <p className="mb-5 text-justify">
              She likes reading, bird watching and nature hiking.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile9;
