import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import t1 from "../assets/images/training/1.png";
import t2 from "../assets/images/training/2.png";
import t3 from "../assets/images/training/3.png";
import t4 from "../assets/images/training/4.png";
import t5 from "../assets/images/training/5.png";
import t6 from "../assets/images/training/6.png";
import t7 from "../assets/images/training/7.png";
import t8 from "../assets/images/training/8.png";
import t9 from "../assets/images/training/9.png";
import t10 from "../assets/images/training/10.png";
import t11 from "../assets/images/training/11.png";
import t12 from "../assets/images/training/12.png";
import t13 from "../assets/images/training/13.png";
import t14 from "../assets/images/training/14.png";
import t15 from "../assets/images/training/15.png";
import t16 from "../assets/images/training/16.png";
import t17 from "../assets/images/training/17.png";

// Define the array of images here
const photos = [
  { src: t1, width: 1000, height: 667 },
  { src: t2, width: 1000, height: 667 },
  { src: t3, width: 1000, height: 667 },
  { src: t4, width: 1000, height: 667 },
  { src: t5, width: 1000, height: 667 },
  { src: t6, width: 1000, height: 667 },
  { src: t7, width: 1000, height: 667 },
  { src: t8, width: 1000, height: 667 },
  { src: t9, width: 1000, height: 667 },
  { src: t10, width: 1000, height: 667 },
  { src: t11, width: 1000, height: 667 },
  { src: t12, width: 1000, height: 667 },
  { src: t13, width: 1000, height: 667 },
  { src: t14, width: 1000, height: 667 },
  { src: t15, width: 1000, height: 667 },
  { src: t16, width: 1000, height: 667 },
  { src: t17, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function TrainingGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Training & Capacity Building
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
