import TeamSlider from "../components/TeamSlider";
import stephen from "../assets/images/team/stephen.jpg";

function TeamProfile4() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={stephen}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Stephen Nassam
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              FINANCE MANAGER
            </h5>
            <p className="mb-5 text-justify">
              Stephen Nassam holds an MBA in Financial Management and is a
              certified PRINCE2 Project Management Practitioner from United
              Kingdom (UK). He has expertise in financial analysis, forecasting
              and excel modeling, with a track record of ensuring projects are
              delivered to the highest quality standard and within budget.
              Stephen is able to effectively organize, manage and utilize all
              financial resources efficiently to achieve project goals. He
              possesses excellent leadership, administrative and project
              management abilities with excellent human relations and brilliant
              presentation skills
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile4;
