import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import fb1 from "../assets/images/fb/1.jpg";
import fb2 from "../assets/images/fb/2.jpg";
import fb3 from "../assets/images/fb/3.jpg";
import fb4 from "../assets/images/fb/4.jpg";
import fb5 from "../assets/images/fb/5.jpg";
import fb6 from "../assets/images/fb/6.jpg";
import fb7 from "../assets/images/fb/7.jpg";
import MetaTags from "../components/MetaTags";

// Define the array of images here
const photos = [
  { src: fb1, width: 1000, height: 667 },
  { src: fb2, width: 1000, height: 667 },
  { src: fb3, width: 1000, height: 667 },
  { src: fb4, width: 1000, height: 667 },
  { src: fb5, width: 1000, height: 667 },
  { src: fb6, width: 1000, height: 667 },
  { src: fb7, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function ForestBirdsGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <MetaTags
        title="Gallery Page - WHRO"
        keywords="Forest, Birds"
        canonical="https://www.whroghana.org/forest-birds-gallery"
      />
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Forest Birds
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
