import Hero from "../components/Hero";
import LogoSlider from "../components/LogoSlider";
import BlogsDisplay from "../components/BlogsDisplay";
import GalleryDisplay from "../components/GalleryDisplay";
import ProjectDisplay from "../components/ProjectDisplay";
import Faq from "../components/Faq";
import TeamDisplay from "../components/TeamDisplay";
import ServicesDisplay from "../components/ServicesDisplay";
import TestimonialDisplay from "../components/TestimonialDisplay";
import ProgrammesDisplay from "../components/ProgrammesDisplay";
import DonationDisplay from "../components/DonationDisplay";
import ResearchDisplay from "../components/ResearchDisplay";
import { Helmet } from "react-helmet-async";
// import PopUp from "../components/PopUp";

function Home() {
  return (
    <>
      {/* <Nav /> */}
      <Helmet>
        <title>WHRO Ghana - Home Page || Welcome to WHRO Ghana, an NGO</title>
        <meta
          name="description"
          content="Welcome to Wildlife and Human Resources Organisation, a non-profit organization focused on building capacity for natural resources protection and conservation."
        />
        <meta
          name="author"
          content="Wildlife and Human Resources Organisation (WHRO)"
        />
        <meta
          name="keywords"
          content="WHRO, Wildlife, Human Resources, Natural Resources Protection, Conservation, Environmental Sustainability, Ghana"
        />
        <meta name="robots" content="index, follow" />
        <meta name="canonical" href="https://www.whroghana.org/" />
        <meta property="og:title" content="WHRO Ghana - Home Page || Welcome to WHRO Ghana, an NGO" />
        <meta
          property="og:description"
          content="Welcome to Wildlife and Human Resources Organisation, a non-profit organization focused on building capacity for natural resources protection and conservation."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.whroghana.org/" />
        {/* <meta
          property="og:image"
          content="https://www.yoursite.com/images/og-image.jpg"
        /> */}
      </Helmet>
      {/* <PopUp /> */}
      <h1 className="hidden">Home - WHRO Ghana</h1>
      <Hero />
      <LogoSlider />
      <ServicesDisplay />
      <ProjectDisplay />
      <ProgrammesDisplay />
      <BlogsDisplay />
      <TestimonialDisplay />
      <GalleryDisplay />
      <TeamDisplay />
      <Faq />
      <ResearchDisplay />
      <DonationDisplay />
      {/* <Footer /> */}
    </>
  );
}

export default Home;
