import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Pub5() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div id="programmes" className="py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32 mx-auto">
            <iframe
              src={
                "/publications/Ferrera et al. 2021- Genetic structure of olive ridley.pdf"
              }
              className="w-full h-[500px] lg:w-[900px] lg:h-[600px] mx-auto"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pub5;
