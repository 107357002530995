import TeamSlider from "../components/TeamSlider";
import laila from "../assets/images/team/laila.jpg";

function TeamProfile7() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={laila}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Dr. Laila Gifty Akita
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              BOARD MEMBER
            </h5>
            <p className="mb-5 text-justify">
              Dr Lailah Gifty Akita (PhD) is an Oceanographer who teaches at
              Department of Marine and Fisheries Sciences, University of Ghana.
              Her research interest lies in Ecology, environmental climate, and
              ecosystems changes. She has several years’ experience in
              conservation of marine resources and has converted her research
              into practical solution in addressing marine pollution.
            </p>
            <p className="mb-5 text-justify">
              Lailah loves to inspire young people to develop their full
              potential in addressing environmental issues.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile7;
