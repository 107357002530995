// import { Link } from "react-router-dom";
// import img1 from "../assets/images/blog.jpg";
import biodiversity from "../assets/images/biodiversity.jpg";
import environmental from "../assets/images/environmental.png";
import habitat from "../assets/images/habitat.png";
import monitoring from "../assets/images/monitoring.jpg";
import Header from "../components/Header";
import header from "../assets/images/services.jpg";

function Services() {
  return (
    <>
      <Header
        img={header}
        subHeading="EXPLORE OUR OFFERINGS"
        mainHeading="All About Our Services"
      />
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16 lg:pt-32">
          <div className="container">
            {/* <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-2"
            >
              WHAT WE ARE DOING
            </h6>
            <h3 className="text-center main-heading font-medium text-2xl md:text-4xl mb-14">
              Our Current Projects
            </h3> */}
            <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10 lg:gap-y-20">
              <div className="flex w-full md:w-1/2 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={monitoring} />
                  <h6 className="mt-4 text-xl font-semibold md:min-h-[56px] xl:min-h-0">
                    Training & Capacity Building
                  </h6>
                  <p className="mt-2">
                    WHRO provides cutting edge training for institutions on
                    various subjects including environmental, livelihoods, and
                    ecotourism. We also offer corporate training in the areas of
                    team building and leadership. WHRO uses both classroom and
                    field-based methods to train and build the capacity of our
                    clients. Our team uses practical and hands-on exercises and
                    approaches to ensure that our clients obtain relevant skills
                    in the various areas we train that stay with them throughout
                    their career. We have conducted training for corporate
                    bodies in the Oil and Gas industry, ports and Harbour, and
                    Energy sector. We also train local communities in
                    income-generating livelihoods and sustainability issues.
                  </p>
                </div>
              </div>

              <div className="flex w-full md:w-1/2 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={biodiversity} />
                  <h6 className="mt-4 text-xl font-semibold md:min-h-[56px] xl:min-h-0">
                    Biodiversity Monitoring
                  </h6>
                  <p className="mt-2">
                    Our experts provide monitoring services for biodiversity
                    assets such as vegetation/flora, marine mammals, sea
                    turtles, avifauna (shorebirds, forest birds, vultures),
                    reptiles, amphibians, and terrestrial mammals. We have
                    assisted with monitoring of biodiversity for both national
                    and international companies in the field of oil and gas,
                    port and harbors, roads and highways, railways, and Energy
                    companies. With deep insight into IFC performance standards
                    and mitigation hierarchy, our team has developed several
                    Biodiversity Action Plans and facilitated the achievement of
                    No Net Loss (NNL) for companies.
                  </p>
                </div>
              </div>

              <div className="flex w-full md:w-1/2 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={environmental} />
                  <h6 className="mt-4 text-xl font-semibold md:min-h-[56px] xl:min-h-0">
                    Environmental Monitoring
                  </h6>
                  <p className="mt-2">
                    Our primary area of expertise is in both terrestrial and
                    aquatic biodiversity assessment and monitoring. We operate
                    in diverse fields including water quality, fisheries,
                    mammals, avifauna, sea turtles, reptiles, amphibians, and
                    vegetation (flora). Our many years of experience in
                    environmental monitoring and assessment ensure that we
                    always deliver quality to our clients. We use current
                    protocols of international standard to undertake various
                    environmental assessments and monitoring to obtain robust
                    data to support companies in the planning and developing
                    EIAs and obtaining permitting. We also undertake feasibility
                    studies for all projects and provide practical advice to
                    clients on proposed interventions. We also carry out
                    assessment of potential impacts of proposed projects on
                    social and economic systems. With our many years of
                    community engagement experience, we assist our clients by
                    engaging with their project stakeholders to ensure smooth
                    operations.
                  </p>
                </div>
              </div>

              <div className="flex w-full md:w-1/2 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={habitat} />
                  <h6 className="mt-4 text-xl font-semibold md:min-h-[56px] xl:min-h-0">
                    Habitat Restoration and Ecosystem Conservation
                  </h6>
                  <p className="mt-2">
                    WHRO does not only undertake habitat restoration but also
                    train institutions and local community members to be able to
                    undertake such restoration activities. Our team has been
                    involved in mangrove restoration in most areas of Ghana and
                    trained local community in the establishment of mangrove
                    nurseries, operation of nurseries, and restoration of
                    degraded lands. Experts of WHRO have developed and helped
                    implement Habitatat Restoration and Management Plans for
                    companies to achieve a no net loss status and improve
                    ecosystem services around their project area.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
