import sthumb1 from "../assets/images/conference.jpg";
import sthumb2 from "../assets/images/blog2.jpeg";
import sthumb3 from "../assets/images/beach_cleanup/4.jpg";
import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";

import thumb2 from "../assets/images/blog3.jpg";
import thumb3 from "../assets/images/blog3-2.png";
import thumb4 from "../assets/images/blog3-3.png";
import facebook from "../assets/images/facebook_g.svg";
import twitter from "../assets/images/twitter_g.svg";
import instagram from "../assets/images/instagram_g.svg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function Blog3() {
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog"
        canonical="https://www.whroghana.org/blog3"
      />
      <div className="team-display-section py-14 md:py-16 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap box-border md:px-10">
            <div className="w-full xl:w-[70%] xl:px-10 box-border mb-20 xl:mb-0">
              <div className="article">
                <img alt="WHRO team at asaase radio" src={thumb2} className="mb-6" />
                <div className="flex items-center my-5">
                  <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                    MEDIA
                  </span>
                  <h6 className="ml-5">
                    By <span className="publisher">Patrick Abbeyquaye</span>{" "}
                    published <span className="pub-date">2023</span>
                  </h6>
                </div>
                <h1 className="text-4xl mb-2 font-medium">
                  Experts Discuss Urgent Need for Sea Turtle Conservation in
                  Ghana
                </h1>

                <p className="article-par">
                  Five of the world&apos;s seven sea turtle species call
                  Ghana&apos;s coastal waters home, with three choosing our
                  beaches as their nesting grounds. Yet, this blessing comes
                  with an urgent call to action. Poaching casts a shadow over
                  their future; sea turtles, all of them endangered, dwindling
                  at an alarming rate due to human activities that threaten
                  their very existence. Climate change adds another layer of
                  complexity, skewing the delicate balance of sexes among
                  hatchlings. Plastic pollution, especially the deceptive white
                  plastics, poses a deadly threat, mistakenly ingested as
                  jellyfish. But there is hope in the form of Ghana&apos;s
                  introduction of National Sea Turtle Day, an opportunity to
                  engage and educate our youth, harness the power of social
                  media, clean our beaches, and champion the banning of
                  single-use plastics.
                </p>
                <p className="article-par">
                  Accra, Ghana - On September 5, 2023, the SDGHub hosted an
                  insightful radio discussion featuring Dr. Andrews Agyehumhene,
                  a lecturer and researcher at the Department of Marine and
                  Fisheries Sciences, University of Ghana, and Ms. Betty Delali
                  Dordzi, Projects Manager at the Wildlife and Human Resource
                  Organization (WHRO). They joined the Young Reporters for the
                  Environment –Ghana on Asaase 99.5 MHz radio in Accra to shed
                  light on the critical issues discussed at the 2nd Ghana
                  National Sea Turtle Conference. The conversation, extracted
                  from the radio talk, emphasized the pressing need for sea
                  turtle conservation and the detrimental impact of neglecting
                  these vital marine creatures on Ghana&apos;s coastal
                  ecosystems.
                </p>
                <h5 className="text-2xl mb-6 font-medium">
                  Dr. Andrews Agyehumhene&apos;s Insights
                </h5>
                <img alt="A photograph of Dr. Andrews Agyehumhene" src={thumb3} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  A photograph of Dr. Andrews Agyehumhene, a lecturer and
                  researcher at the Department of Marine and Fisheries Sciences,
                  University of Ghana, as he makes his submission on a raised
                  issue.
                </p>
                <p className="article-par">
                  Dr. Agyehumhene, known for his extensive work in marine and
                  fisheries sciences, stressed the importance of research
                  benefiting ordinary Ghanaians. He highlighted the pivotal role
                  sea turtles play in maintaining the ocean&apos;s health and
                  ecological balance. Sea turtles are known for their remarkable
                  ability to clean the ocean by consuming sea grass, preventing
                  overgrowth and decomposition, thus preserving the marine
                  environment.
                </p>
                <p className="article-par">
                  One significant point of discussion was the positive
                  correlation between healthy sea turtle populations and
                  increased fish stocks, which in turn can lead to higher
                  economic benefits and profits for local fishing communities.
                  Sea turtles&apos; predation on jellyfish, a natural predator
                  of young fish, contributes to a balanced ecosystem and
                  improving fish stocks. Dr. Agyehumhene strongly advocated for
                  the prioritization of sea turtle conservation by the Ministry
                  of Fisheries in Ghana.
                </p>
                <h5 className="text-2xl mb-6 font-medium">
                  Ms. Betty Delali Dordzi&apos;s Insights
                </h5>
                <img alt=" A photograph of Ms. Betty Delali Dordzi" src={thumb4} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  A photograph of Ms. Betty Delali Dordzi, Projects Manager at
                  the Wildlife and Human Resource Organization (WHRO), giving
                  her submission on a raised issue.
                </p>
                <p className="article-par">
                  Ms. Betty Delali Dordzi, who manages projects at WHRO,
                  provided valuable insights into the life cycle and challenges
                  faced by sea turtles in Ghana. She highlighted that while
                  there are male and female sea turtles, it is the females that
                  predominantly come to the beaches to nest. She described the
                  remarkable journey of sea turtles, from nesting to the
                  hatching of their eggs and their return to the very beaches
                  where they were born.
                </p>
                <p className="article-par">
                  Emphasizing the urgent need for sea turtle conservation, Miss
                  Dordzi pointed out that all sea turtles in Ghana are
                  endangered, with poaching being the primary threat they face.
                  She expressed concern about the common practice of capturing
                  and killing sea turtles in coastal communities.
                </p>
                <p className="article-par">
                  Miss Dordzi also addressed the heart-wrenching issue of
                  poachers digging out sea turtle eggs, endangering the
                  species&apos; future. She highlighted the need for immediate
                  action to protect these creatures and emphasized WHRO&apos;s
                  commitment to spreading the message of sea turtle protection.
                </p>
                <p className="article-par">
                  Both experts discussed the significant consequences of not
                  protecting sea turtles, including disruptions to their
                  ecological roles, which would affect fish stocks and the
                  overall health of Ghana&apos;s oceans. They also stressed the
                  economic importance of sea turtles, as they attract tourists
                  who contribute to Ghana&apos;s revenue.
                </p>
                <p className="article-par">
                  Dr. Agyehumhene underscored the fact that one live turtle is
                  worth more than a turtle consumed for a week, given their long
                  lifespan and vital ecological contributions.
                </p>
                <p className="article-par">
                  Miss Betty Delali Dordzi emphasized on the need for actively
                  addressing sea turtle conservation challenges. She emphasized
                  the importance of preventing plastic pollution, which is a
                  major threat to sea turtles. Plastics, especially white ones,
                  often end up in the sea and are mistaken for food by turtles,
                  leading to their unintentional deaths.
                </p>
                <p className="article-par">
                  The experts also touched upon the issue of bycatch, where sea
                  turtles are unintentionally caught by fishermen, further
                  endangering their populations. Dr. Agyehumhene discussed the
                  alarming statistics of sea turtles caught by artisanal fish
                  vessels in Ghana and said an average of 4 turtles are capture
                  by a vessel each year, which considering the over twelve
                  thousand artisanal vessels in Ghana’s waters, makes it a
                  significant threat.
                </p>
                <h5 className="text-2xl mb-6 font-medium">
                  Enforcing Conservation Laws
                </h5>
                <p className="article-par">
                  Ghana has existing Wildlife Conservation Regulations of
                  1971,the Fisheries Act of 2002, as well as the the Wildlife
                  Resource Management Act 2023. However, implementing these laws
                  faces several challenges, including logistical constraints,
                  lack of awareness among officers, and interference from higher
                  authorities. The experts emphasized the need for stronger
                  institutions to effectively enforce these vital conservation
                  laws.
                </p>
                <h5 className="text-2xl mb-6 font-medium">
                  Creating Awareness and Youth Engagement
                </h5>
                <p className="article-par">
                  The recent Ghana National Sea Turtle Conference aimed to bring
                  together stakeholders and NGOs working on sea turtle
                  conservation. It also focused on creating awareness and
                  fostering youth engagement in the conservation efforts. Ms.
                  Dordzi stressed the importance of educating and building the
                  capacity of young people, who can influence older generations
                  to change harmful practices.
                </p>
                <h5 className="text-2xl mb-6 font-medium">A Call to Action</h5>
                <p className="article-par">
                  The recent Ghana National Sea Turtle Conference aimed to bring
                  together stakeholders and NGOs working on sea turtle
                  conservation. It also focused on creating awareness and
                  fostering youth engagement in the conservation efforts. Ms.
                  Dordzi stressed the importance of educating and building the
                  capacity of young people, who can influence older generations
                  to change harmful practices.
                </p>
                <p className="article-par">
                  Dr. Agyehumhene encouraged individuals to report any illegal
                  activities related to sea turtles to wildlife offices or
                  police stations. He also advocated for keeping beaches clean
                  and highlighted the urgent need to ban single-use plastics.
                </p>
                {/* <p className="article-par">
                  <i>
                    <strong>
                      Urgent action is needed to protect these remarkable
                      creatures and the delicate balance they bring to the
                      marine ecosystem.
                    </strong>
                  </i>
                </p> */}
                <p className="article-par">
                  In their closing remarks, both experts emphasized that sea
                  turtle conservation is not just an environmental issue but a
                  crucial component of Ghana&apos;s ecological, economic, and
                  cultural heritage. Urgent action is needed to protect these
                  remarkable creatures. As they embark on this mission, WHRO and
                  its partners remain committed to the cause of sea turtle
                  protection.
                </p>
                <p>
                  For more information on sea turtle conservation efforts and
                  updates, stay tuned to WHRO&apos;s initiatives and the annual
                  National Sea Turtle Day, observed on the last Friday of August
                  each year, when various awareness activities are organized to
                  celebrate and protect these magnificent creatures.
                  {/* <a href="https://www.whroghana.org"> www.whroghana.org.</a> */}
                </p>
              </div>
              <div className="flex pt-4 gap-x-5 text-green-900 md:mt-10">
                <a href="https://www.facebook.com/whroghana/" target="_blank">
                  <img alt="Facebook icon" className="h-5" src={facebook} />
                </a>
                <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                  <img alt="Twitter icon" className="h-5" src={twitter} />
                </a>
                <a href="https://www.instagram.com/whroghana/" target="_blank">
                  <img alt="Instagram icon" className="h-5" src={instagram} />
                </a>
              </div>
              <div className="comments mt-20">
                <h1 className="text-2xl mb-5">Leave a comment</h1>
                <form>
                  <textarea
                    className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                    placeholder="Comments*"
                  ></textarea>
                  <div className="flex box-border gap-x-10">
                    <div className="w-1/2">
                      <input
                        type="text"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Full Name*"
                      />
                    </div>
                    <div className="w-1/2">
                      <input
                        type="email"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full xl:w-[30%] xl:pr-10 box-border">
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog7">
                  <div className="w-1/4">
                    <img alt="WHRO Celebrates World Oceans Day 2024" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2024</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO Celebrates World Oceans Day 2024 with Awareness
                      Creation and Beach Cleanup
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog5">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="Sea
                      Turtles" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div>
              {/* <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="Sea Turtle Conservation in
                      Ghana" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div> */}
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="Ms. Delali Betty Dordzi Speaks" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog">
                  <div className="w-1/4">
                    <img alt="2nd Ghana National Sea Turtle Conference 2023" src={sthumb1} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      2nd Ghana National Sea Turtle Conference 2023
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog3;
