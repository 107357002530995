// import { Link } from "react-router-dom";
// import turtles from "../assets/images/turtles.jpg";
import { Link } from "react-router-dom";
import internship from "../assets/images/internship2.png";
// import ysp from "../assets/images/ysp.jpg";

function InternshipProgramme() {
  return (
    <>
      {/* <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-3 text-white"
              >
                EVERYTHING WE RUN
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                All About Our Programmes
              </h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16 lg:pt-32">
          <div className="container">
            <div className="ll:px-48">
              <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10 lg:gap-y-20">
                <div className="flex w-full md:px-5 justify-center">
                  <div>
                    <h3 className="mb-10 text-center text-2xl md:text-4xl font-semibold md:min-h-[56px] xl:min-h-0 text-green-800">
                      Volunteer & Internship Programme
                    </h3>
                    <img className="w-3/4 mx-auto" src={internship} />
                    <p className="mt-10">
                      At WHRO, we consider volunteering and internship as an
                      important and integral component of its activities. We at
                      WHRO believe that each volunteer and intern possess unique
                      and valuable skills, which when developed could help
                      promote natural resource conservation. The organisation
                      therefore provides the best platform for volunteers and
                      interns to identify, harness, and develop their innate
                      skill, while learning new ones through practical and
                      field-based activities.
                    </p>
                    <p className="mt-2">
                      Some volunteers and interns who passed through WHRO have
                      gone on to higher platforms and making impacts locally and
                      globally.
                    </p>
                    <p className="mt-2">
                      Join WHRO’s Volunteer and Internship programme now and
                      contribute your skills to the conservation of natural
                      resources in Ghana and around the globe.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-10 md:pt-20">
              <Link to="/interns-gallery">
                <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                  View Gallery
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default InternshipProgramme;
