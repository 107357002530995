import { Link } from "react-router-dom";
import facebook from "../assets/images/facebook.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import MetaTags from "../components/MetaTags";

function Contact() {
  return (
    <>
      <MetaTags
        title="Contact Page - WHRO"
        keywords="Contact, Phone Number, Email, "
        canonical="https://www.whroghana.org/contact"
      />
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-1 text-white"
              >
                CONTACT US
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                We&apos;d Love to Hear From You
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section pb-14 md:pb-16 lg:pb-32 lg:px-32">
        <div className="container">
          <div className="-mt-16 z-10 bg-green-200 md:mx-9 flex flex-col-reverse xl:flex-row shadow-lg rounded-md">
            <div className="w-full xl:w-1/2 ll:w-3/5 py-16 px-4 md:px-20">
              <h3 className="text-center main-heading font-medium text-xl md:text-2xl mb-14">
                Send Us a Message
              </h3>
              <form>
                <input
                  type="text"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                  placeholder="Full Name*"
                ></input>
                <input
                  type="email"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                  placeholder="Email*"
                ></input>
                <input
                  type="tel"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                  placeholder="Phone Number*"
                ></input>
                <textarea
                  className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                  placeholder="Message*"
                ></textarea>
                <button
                  type="submit"
                  className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                >
                  SUBMIT
                </button>
              </form>
            </div>
            <div className="xl:w-1/2 ll:w-2/5 py-16 px-4 md:px-20 bg-green-900">
              <h3 className="text-center main-heading font-medium text-xl md:text-2xl mb-14 text-footer-white">
                Contact Information
              </h3>
              <div className="md:px-10 w-full md:w-2/4 xl:w-[80%] flex flex-col gap-y-10 xl:gap-y-16 mx-auto">
                <div className="w-full">
                  <Link to="/">
                    <h6 className="text-footer-white lg:mb-4">
                      8th Ali-Ago Street
                    </h6>
                  </Link>
                  <Link to="/">
                    <h6 className="text-footer-white lg:mb-4">East - Legon</h6>
                  </Link>
                  <Link to="/">
                    <h6 className="text-footer-white lg:mb-4">
                      P.O Box LG 485, Legon
                    </h6>
                  </Link>
                  <Link to="/">
                    <h6 className="text-footer-white lg:mb-4">
                      +233 30 255 4732
                    </h6>
                  </Link>
                  <Link to="/">
                    <h6 className="text-footer-white lg:mb-4">
                      +233 53 513 7979
                    </h6>
                  </Link>
                  <Link to="/">
                    <h6 className="text-footer-white lg:mb-4">
                      info@whroghana.org
                    </h6>
                  </Link>

                  <div className="w-full flex justify-between mt-10">
                    <Link to="/">
                      <img className="h-8" src={facebook} />
                    </Link>
                    <Link to="/">
                      <img className="h-8" src={twitter} />
                    </Link>
                    <Link to="/">
                      <img className="h-8" src={instagram} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
