import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import hero1 from "../assets/images/hero_1.jpg";
import hero2 from "../assets/images/hero_2.jpg";
// import hero3 from "../assets/images/hero_3.jpg";
import hero4 from "../assets/images/hero4.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Hero() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  const images = [
    {
      original: hero1,
      originalAlt: "meadows",
      subHeading: "Welcome",
      mainHeading: "Wildlife & Human Resources Organisation",
      buttonText: "Explore",
      link: "",
    },
    {
      original: hero2,
      originalAlt: "swimming turtle",
      subHeading: "Volunteer",
      mainHeading: "Show You Care",
      buttonText: "Join Us",
      link: "volunteer",
    },
    {
      original: hero4,
      originalAlt: "waterfall",
      subHeading: "Proactivity",
      mainHeading: "Help Preserve The Environment For Future Generations",
      buttonText: "Learn More",
      link: "",
    },
  ];

  const renderCustomItem = (item) => {
    return (
      <div
        data-aos="zoom-in"
        data-aos-delay="300"
        className="image-gallery-image"
      >
        <img className="w-full" src={item.original} alt={item.originalAlt} />
        <div className="image-gallery-description flex-col text-5xl align-middle justify-center">
          <h6 className="mini-heading mb-2 md:mb-5 text-xs lg:text-sm">
            {item.subHeading}
          </h6>
          <h1 className="mb-2 md:mb-5 text-2xl md:text-5xl ll:text-7xl font-normal">
            {item.mainHeading}
          </h1>
          {item.link === "" ? (
            <Link to={"#clients"}>
              <button className="bg-white py-3 px-12 text-green-900 md:text-lg rounded-2xl md:rounded-3xl hover:bg-green-900 hover:text-footer-white transition duration-300 ease-in-out">
                {item.buttonText}
              </button>
            </Link>
          ) : (
            <Link to={"/" + item.link}>
              <button className="bg-white py-3 px-12 text-green-900 md:text-lg rounded-2xl md:rounded-3xl hover:bg-green-900 hover:text-footer-white transition duration-300 ease-in-out">
                {item.buttonText}
              </button>
            </Link>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <ImageGallery
        autoPlay={true}
        slideInterval={5000}
        items={images}
        renderItem={renderCustomItem}
      />
    </>
  );
}

export default Hero;
