import m from "../assets/images/about3.png";
import about from "../assets/images/about.png";
import about2 from "../assets/images/about2.jpg";
import check from "../assets/images/check.png";
import MetaTags from "../components/MetaTags";
import Header from "../components/Header";
import header from "../assets/images/whro_header.jpg";

function About() {
  return (
    <>
      <MetaTags
        title="About Page - WHRO"
        keywords="About, Mission, Vision, What We Do"
        canonical="https://www.whroghana.org/about"
      />
      <Header
        img={header}
        subHeading="ABOUT US"
        mainHeading="Find Out Who We Are"
      />
      <div
        id="whoweare"
        className="team-display-section bg- py-14 md:py-16 lg:py-32"
      >
        <div className="container">
          <div className="ll:px-32">
            <div className="flex flex-col lg:flex-row ll:items-center">
              <div className="basis-1/2 md:px-10 lg:px-5">
                <img alt="sea turtle shell measurement" className="rounded-lg mb-5" src={about}/>
              </div>
              <div className="basis-1/2 md:px-10 lg:px-5">
                <h3 className="text-center text-2xl md:text-3xl font-semibold mb-5 text-green-900">
                  Who We Are
                </h3>
                <p className="mb-5">
                  Wildlife and Human Resources Organisation (WHRO) is a
                  non-profit organisation aimed at building capacity for natural
                  resources protection and conservation around the globe.
                </p>
                <p className="mb-5">
                  Founded in December 2019, Wildlife and Human Resources
                  Organisation has as its core mandate to improve natural
                  resources and build human capacity for a sustainable
                  environment. WHRO is currently a statutory body registered
                  under the Ghana companies’ Act of 1963 (Act 179) with
                  certificate of incorporation number CG167072019.
                </p>
                <p className="mb-5">
                  WHRO is a strong voice for the environment, promoting
                  solutions through research, consultation, education and
                  partnership.
                </p>
                <p id="mission" className="mb-5">
                  The core mandate – Inspiring people to achieve a healthy
                  environment is our driving principle.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row mt-14 md:mt-16 lg:mt-32 items-center">
              <div className="basis-1/2 md:px-10 lg:px-5">
                <h3 className="text-center text-2xl md:text-3xl font-semibold mb-5 text-green-900">
                  Mission
                </h3>

                <p className="mb-5">
                  Working with all relevant stakeholders to protect, restore and
                  sustain our environment by promoting solutions through
                  research, consultation, education and partnerships.
                </p>

                <h3 className="text-center text-2xl md:text-3xl font-semibold mt-10 mb-5 text-green-900">
                  Vision
                </h3>

                <p id="objectives" className="mb-5">
                  Inspiring people to achieve a healthy environment for enhanced
                  economic and socio-cultural development.
                </p>
              </div>
              <div className="basis-1/2 md:px-10 lg:px-5">
                <img alt="young scientists programme" className="rounded-lg mb-5" src={about2}></img>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-14 md:mt-16 lg:mt-32 items-center">
              <div className="basis-1/2 md:px-10 lg:px-5">
                <img alt="mangrove conservation" className="rounded-lg mb-5" src={m}></img>
              </div>
              <div className="basis-1/2 md:px-10 lg:px-5">
                <h3 className="text-center text-2xl md:text-3xl font-semibold mb-5 text-green-900">
                  Objectives
                </h3>
                <p className="mb-2">
                  The main objective of WHRO is to enhance the ecological
                  integrity of all natural resources to improve their ecosystem
                  services for the benefit of present and future generations.
                </p>
                <p className="mb-2">WHRO achieves this objective through:</p>
                <ul>
                  <li className="mb-3">
                    <span>
                      <img alt="check mark icon" className="h-4 inline-block mr-3" src={check} />
                    </span>
                    Building capacities at all levels in the areas of
                    environmental and natural resource management.
                  </li>
                  <li className="mb-3">
                    <span>
                      <img alt="check mark icon" className="h-4 inline-block mr-3" src={check} />
                    </span>
                    Conducting environmental and natural resources related
                    assessments.
                  </li>
                  <li className="mb-3">
                    <span>
                      <img alt="check mark icon" className="h-4 inline-block mr-3" src={check} />
                    </span>
                    Undertaking community based projects that enhances
                    ecosystems, improve livelihoods and increase income of
                    resource dependent communities.
                  </li>
                  <li className="mb-3">
                    <span>
                      <img alt="check mark icon" className="h-4 inline-block mr-3" src={check} />
                    </span>
                    Providing information and expert advice on environmental and
                    natural resources management for policy making.
                  </li>
                  <li className="mb-3">
                    <span>
                      <img alt="check mark icon" className="h-4 inline-block mr-3" src={check} />
                    </span>
                    Developing livelihoods and ecotourism potentials around
                    natural resources for their protection and improved
                    benefits.
                  </li>
                  <li className="mb-3">
                    <span>
                      <img alt="check mark icon" className="h-4 inline-block mr-3" src={check} />
                    </span>
                    Creating awareness on environmental sustainability
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
