import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import ysp1 from "../assets/images/ysp/2.jpg";
import ysp2 from "../assets/images/ysp/4.jpg";
import ysp3 from "../assets/images/ysp/8.jpg";
import ysp4 from "../assets/images/ysp/9.jpg";
import ysp5 from "../assets/images/ysp/10.jpg";
import ysp6 from "../assets/images/ysp/11.jpg";
import ysp7 from "../assets/images/ysp/20.jpg";
import ysp8 from "../assets/images/ysp/21.jpg";
import ysp9 from "../assets/images/ysp/26.jpg";
import ysp10 from "../assets/images/ysp/29.jpg";
import ysp11 from "../assets/images/ysp/30.jpg";
import ysp12 from "../assets/images/ysp/31.jpg";
import ysp13 from "../assets/images/ysp/ysp-header.jpg";
import ysp14 from "../assets/images/ysp/ysp-header-2.jpg";

// Define the array of images here
const photos = [
  { src: ysp1, width: 1000, height: 667 },
  { src: ysp2, width: 1000, height: 667 },
  { src: ysp3, width: 1000, height: 667 },
  { src: ysp4, width: 1000, height: 667 },
  { src: ysp5, width: 1000, height: 667 },
  { src: ysp6, width: 1000, height: 667 },
  { src: ysp7, width: 1000, height: 667 },
  { src: ysp8, width: 1000, height: 667 },
  { src: ysp9, width: 1000, height: 667 },
  { src: ysp10, width: 1000, height: 667 },
  { src: ysp11, width: 1000, height: 667 },
  { src: ysp12, width: 1000, height: 667 },
  { src: ysp13, width: 1000, height: 667 },
  { src: ysp14, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function YspGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Young Scientists Programme
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
