// import header from "../assets/images/whro_header.jpg";

function Header({ img, subHeading, mainHeading }) {
  return (
    <div className="z-0">
      <div className="relative box-border">
        <img alt="team image" src={img} className="w-full" />
        <div className="absolute flex top-1/2 -translate-y-1/2 left-0 right-0 box-border">
          <div className="w-full">
            <h6
              className="text-center 
                text-xs md:text-sm mini-heading mb-1 text-white"
            >
              {subHeading}
            </h6>
            <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl">
              {mainHeading}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
