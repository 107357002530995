import { Link } from "react-router-dom";
// import img1 from "../assets/images/blog.jpg";
import eni from "../assets/images/eni1.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function ProjectDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div
        id="projects"
        className="projects-display pt-14 md:pt-16 lg:pt-32 xl:px-52"
      >
        <div className="container">
          <div data-aos="fade-down">
            <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-2"
            >
              WHAT WE ARE DOING
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Our Current Projects
            </h3>
            <p className="text-green-800 text-sm pl-5 mb-3">
              *Experts from WHRO have undertaken various environmental
              assessment projects for the following institutions
            </p>
          </div>
          <div className="flex flex-col xl:items-center md:flex-row lg:px-5 flex-wrap justify-center gap-y-10">
            <div
              data-aos="flip-left"
              data-aos-delay="100"
              className="flex w-full md:w-1/2 md:px-5 justify-center"
            >
              <div>
                <img alt="Sea Turtle Monitoring" className="w-full border-2" src={eni} />
                <h6 className="mt-4 text-xl font-semibold">
                  Eni-Ghana (Sea Turtle Monitoring)
                </h6>
                {/* <a>
                  <h6 className="text-sm text-green-800 hover:text-green-600 underline">
                    READ MORE
                  </h6>
                </a> */}
              </div>
            </div>
            {/* <div className="flex w-full md:w-1/2 md:px-5 justify-center">
              <div>
                <img alt="Eni-Ghana" className="w-full border-2" src={eni} />
                <h6 className="mt-4 text-xl font-semibold">
                  Eni-Ghana (Marine Mammal Monitoring)
                </h6>
                <a>
                  <h6 className="text-sm">READ MORE</h6>
                </a>
              </div>
            </div> */}
            <div
              data-aos="flip-right"
              data-aos-delay="100"
              className="flex w-full md:w-1/2 md:px-5 justify-center"
            >
              <div>
                <img alt="Eni-Ghana" className="w-full border-2" src={eni} />
                <h6 className="mt-4 text-xl font-semibold">
                  Eni-Ghana (Avifauna Surveys)
                </h6>
                {/* <a>
                  <h6 className="text-sm text-green-800 hover:text-green-600 underline">
                    READ MORE
                  </h6>
                </a> */}
              </div>
            </div>
            <div
              data-aos="fade-up"
              className="flex w-full justify-center md:pt-10"
            >
              <Link to="/projects">
                <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                  View All Our Projects
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectDisplay;
