import turtles from "../assets/images/turtles.jpg";
import internship from "../assets/images/internship2.png";
import ysp from "../assets/images/ysp.jpg";
import m from "../assets/images/mangrove/7.png";
import s from "../assets/images/com_support.jpg";
import ed1 from "../assets/images/eaa/19.jpg";
import Header from "../components/Header";
import header from "../assets/images/programmes.png";

import { Link } from "react-router-dom";
// import TeamGrid from "../components/TeamGrid";

function ProgrammesList() {
  return (
    <>
      <Header
        img={header}
        subHeading="WHAT WE RUN"
        mainHeading="All Our Programmes"
      />
      <div className="ProgrammesList-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div className="w-full flex flex-col md:flex-row gap-x-2 gap-y-10 flex-wrap box-border justify-center">
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/sea-turtles-programme"}>
                  <img src={turtles} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Sea Turtles Conservation Programme
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/ysp"}>
                  <img src={ysp} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Young Scientists Programme
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/internship-programme"}>
                  <img src={internship} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Volunteer & Internship Progammes
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/mangrove-conservation"}>
                  <img src={m} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Mangrove Conservation
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/community-programme"}>
                  <img src={s} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Community Support
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/education-gallery"}>
                  <img src={ed1} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Education and Awareness
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgrammesList;
