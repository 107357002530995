// import pdf from "../assets/images/pdf.png";
// import mtsg from "/images/mtsg.jpg";
// import degradation from "/images/degradation.jpg";
// import guidelines from "/images/guidelines.jpg";
// import hawksbill from "/images/hawksbill.jpg";
// import illumination from "/images/illumination.jpg";
// import kinixys from "/images/kinixys.jpg";
// import loggerheads from "/images/loggerheads.jpg";
// import mangrove from "/images/mangrove.jpg";
// import marine from "/images/marine.jpg";
// import muniPomadze from "/images/muni-pomadze.jpg";
// import nesting from "/images/nesting.jpg";
// import olivacea from "/images/olivacea.jpg";
// import perceptions from "/images/perceptions.jpg";
// import resilient1 from "/images/resilient-1.jpg";

// import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Pub1() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);

  const pdfUrl = encodeURI(
    "/publications/Agyekumhene et al. 2023- Guidelines for Reducing Poaching.pdf"
  );

  return (
    <>
      <div id="programmes" className="py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32 mx-auto">
            <iframe
              src={pdfUrl}
              className="w-full h-[500px] lg:w-[900px] lg:h-[600px] mx-auto"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pub1;
