import TeamSlider from "../components/TeamSlider";
import christina from "../assets/images/team/christina.jpg";

function TeamProfile6() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={christina}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Christina Appiah - Nimo
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              BOARD MEMBER
            </h5>
            <p className="mb-5 text-justify">
              Christina is a dedicated professional educator in Sustainable
              Marketing and MSME development with a remarkable educational
              journey, including a Bachelor’s, Master’s degree (all from the
              University of Cape Coast), and a Doctorate (Tomaš Bata University
              in Zlin). Her rich academic background equips her to guide
              students in making informed choices about their career paths. With
              her versatile teaching style, she fosters a learning environment
              that accommodates diverse learning styles, drawing students into
              both theoretical and practical aspects of education.
            </p>
            <p className="mb-5 text-justify">
              Beyond her academic achievements, Christina actively engages in
              social and community work, especially within the senior high
              education sector. She passionately helps students navigate the
              complex decision of university program choices, emphasizing the
              alignment of their passions and career aspirations.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 text-justify clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile6;
