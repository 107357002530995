// import { Link } from "react-router-dom";
// import img1 from "../assets/images/blog.jpg";
import eni from "../assets/images/eni1.png";
import mps from "../assets/images/mps.png";
import mba from "../assets/images/mba.png";
import envaserv from "../assets/images/envaserv.png";
import hec from "../assets/images/hec.png";
import esl from "../assets/images/esl.png";
import five from "../assets/images/five.png";
import associated from "../assets/images/associated.png";
import snv from "../assets/images/snv.png";
import seljen from "../assets/images/seljen.png";
import Header from "../components/Header";
import header from "../assets/images/whro_header.jpg";

function Projects() {
  return (
    <>
      {/* <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-1 text-white"
              >
                ALL OUR PROJECTS
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Find Out Everything We Do
              </h3>
            </div>
          </div>
        </div>
      </div> */}
      <Header
        img={header}
        subHeading="ALL OUR PROJECTS"
        mainHeading="Find Out Everything We Do"
      />
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16 lg:pt-32">
          <div className="container">
            <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-2"
            >
              WHAT WE ARE DOING
            </h6>
            <h3 className="text-center main-heading font-medium text-2xl md:text-4xl mb-14">
              Our Current Projects
            </h3>
            <p className="text-green-800 text-sm pl-5 mb-3">
              *Experts from WHRO have undertaken various environmental
              assessment projects for the following institutions
            </p>
            <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10">
              <div className="flex w-full md:w-1/2 md:px-5 justify-center">
                <div>
                  <img className="w-full border-2" src={eni} />
                  <h6 className="mt-4 text-xl font-semibold">
                    Eni-Ghana (Sea Turtle Monitoring)
                  </h6>
                  <p className="mt-2">
                    WHRO has been conducting sea turtle monitoring for Eni Ghana
                    since 2020 and continues to be involved with the assessment
                    of species and abundance of sea turtles in the project area
                    of influence of the company. WHRO employs trained community
                    members to undertake nightly patrols along beaches. Patrol
                    members receive annual training to ensure they are
                    up-to-date on current methods for conducting responsible sea
                    turtle patrols. Data collected is also analyzed by WHRO to
                    support decision making to enable the company to achieve the
                    No Net Loss target. WHRO also engages with community members
                    and other relevant stakeholders within the area of Eni Ghana
                    operations to undertake sea turtle conservation programmes
                    and Additional Conservation Actions (ACAs) that lead to the
                    protection of sea turtles in the project area.
                  </p>
                </div>
              </div>

              <div className="flex w-full md:w-1/2 md:px-5 justify-center">
                <div>
                  <img className="w-full border-2" src={eni} />
                  <h6 className="mt-4 text-xl font-semibold">
                    Eni-Ghana (Avifauna Surveys)
                  </h6>
                  <p className="mt-2">
                    Since 2020, the WHRO team has conducted avifauna monitoring
                    for Eni Ghana and continues to be involved with the monthly
                    and biannual monitoring of avifauna species and abundance of
                    the project area of influence of the company. Among the
                    avifauna species currently being monitored are shorebirds,
                    forest birds, and vultures. WHRO also engages with community
                    members and other relevant stakeholders within the area of
                    Eni Ghana operations to undertake Additional Conservation
                    Actions (ACAs) towards the protection of avifauna.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="projects-display pt-14 md:pt-16 lg:pt-32">
          <div className="container">
            <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-2"
            >
              WHAT WE HAVE DONE
            </h6>
            <h3 className="text-center main-heading font-medium text-2xl md:text-4xl mb-14">
              Our Past Projects
            </h3>
            <p className="text-green-800 text-sm pl-5 mb-3">
              *Experts from WHRO have undertaken various environmental
              assessment projects for the following institutions
            </p>
            <div className="flex flex-col md:flex-row md:px-5 flex-wrap justify-center gap-y-10">
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={mps} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    Meridian Port Services
                  </h6>

                  <p className="mt-2">
                    Sea turtle monitoring and impact offsetting for the Tema
                    Port Expansion Project (Port Operation phase)
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={envaserv} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    EnvaServ Consult Limited
                  </h6>
                  <p className="mt-2">
                    Terrestrial mammal assessment within and around the onshore
                    concession of the Eni Offshore Cape Three Point project at
                    Sanzule, Western Region, Ghana.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={mba} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    MBA Consult
                  </h6>
                  <p className="mt-2">
                    Biodiversity assessment within the proposed site for the
                    Accra Airport City project, Accra.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={hec} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    China Habour Engineering Company
                  </h6>
                  <p className="mt-2">
                    Sea turtle monitoring and impact offsetting for the Tema
                    Port Expansion Project (Port Construction phase).
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={esl} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    ESL Consulting / ERM
                  </h6>
                  <p className="mt-2">
                    Avifauna assessment within and around the proposed project
                    site for the Tamale airport expansion project.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={seljen} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    Seljen Consult
                  </h6>
                  <p className="mt-2">
                    Conducted Vantage Point Bird Survey at Whuti, Anyanui and
                    Srogbe-Salo for a 150MW Windfarm project at Volta Region,
                    Ghana.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={esl} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    ESL Consulting
                  </h6>
                  <p className="mt-2">
                    Vantage Point Bird Survey within project site for the
                    Osudoku Windfarm constructed by EMP Ghana, at Shai-Osudoku
                    District.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={five} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    Five Oceans Environmental Services Limited, UK
                  </h6>
                  <p className="mt-2">
                    Sea turtle ecology and nesting site suitability assessment
                    for the Environmental and Social Impacts Assessment (ESIA)
                    process of Ghana 1000 Gas to Power Project at Aboadze,
                    Western Region, Ghana.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full border" src={associated} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    Associated Consultants Limited
                  </h6>
                  <p className="mt-2">
                    Assessed terrestrial ecology (fauna and flora) for baseline
                    studied of the Environmental and Social Impacts Assessment
                    (ESIA) process for Ghana 1000 Gas to Power Project at
                    Aboadze, Western Region, Ghana.
                  </p>
                </div>
              </div>
              <div className="flex w-full md:w-1/2 lg:w-1/3 ll:w-1/4 md:px-5 justify-center">
                <div>
                  <img className="w-full" src={snv} />
                  <h6 className="mt-4 text-xl font-semibold min-h-[56px]">
                    SNV Ghana
                  </h6>
                  <p className="mt-2">
                    Assessed Mangrove Cultivation and Management issues and
                    options along the entire coast of Ghana for the Improved
                    Fish Smoking Project by SNV Netherlands Development
                    Organisation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Projects;
