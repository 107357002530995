import TeamSlider from "../components/TeamSlider";
import james from "../assets/images/team/james.jpg";

function TeamProfile8() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={james}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              James Biney
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              BOARD MEMBER
            </h5>
            <p className="mb-5 text-justify">
              James Biney, is a retired Wildlife Officer and worked as a
              professional Tour Guide and certified birder of the Wildlife
              Division of the Forestry Commission of Ghana for over 25 years. He
              has extensive knowledge in birds across all major bird hotspots
              and habitat types in Ghana including coastal, savannah and forest.
              He served as head of birdwatchers at Ghana’s largest protected
              area, Mole National Park for more than 10 years. James has
              collaborated with number of ornithologists in conducting bird
              surveys in Ghana and has contributed to several research works on
              birds in Ghana.
            </p>
            <p className="mb-5 text-justify">
              For the past 12 years, he has served as a field assistant to
              researchers and students from major universities in Ghana and the
              world. Till retirement, he was the head of Birdwatching Tour
              Guides in Ghana’s most visited protected area, Kakum National
              Park. He is a fellow of Association of Birdwatchers in Ghana,
              Central Region, and an advocate for protection of terrestrial and
              coastal birds. His 30 years’ work experience in major national
              parks in Ghana has helped transform bird watching activity in most
              national parks in Ghana including the Kakum National Park.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile8;
