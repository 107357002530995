import sthumb1 from "../assets/images/conference.jpg";
import sthumb2 from "../assets/images/blog2.jpeg";
import sthumb3 from "../assets/images/blog3.jpg";
import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";

import thumb1 from "../assets/images/beach_cleanup/4.jpg";
import thumb2 from "../assets/images/eaa/05.jpg";
import thumb3 from "../assets/images/beach_cleanup/52.jpg";
import facebook from "../assets/images/facebook_g.svg";
import twitter from "../assets/images/twitter_g.svg";
import instagram from "../assets/images/instagram_g.svg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function Blog7() {
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog"
        canonical="https://www.whroghana.org/blog7"
      />
      <div className="team-display-section py-14 md:py-16 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap box-border md:px-10">
            <div className="w-full xl:w-[70%] xl:px-10 box-border mb-20 xl:mb-0">
              <div className="article">
                <img alt="WHRO Celebrates World Oceans Day 2024" src={thumb1} className="mb-6" />
                <div className="flex items-center my-5">
                  <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                    MEDIA
                  </span>
                  <h6 className="ml-5">
                    By <span className="publisher">Jennifer Dede Ankrah</span>{" "}
                    published <span className="pub-date">2024</span>
                  </h6>
                </div>
                <h1 className="text-4xl mb-2 font-medium">
                  WHRO Celebrates World Oceans Day 2024 with Awareness Creation
                  and Beach Cleanup
                </h1>

                <p className="article-par">
                  The Wildlife and Human Resources Organization (WHRO) embarked
                  on a series of activities to mark World Oceans Day 2024 which
                  was under the theme “Catalyzing Action for Our Ocean and
                  Climate”. These activities include awareness programs in
                  school and community, and a beach cleanup to promote
                  sanitation along community fish landing sites.
                </p>
                <img alt="Students listening attentively to Dr. Andrews
                  Agyekumhene" src={thumb2} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  Students listening attentively to Dr. Andrews
                  Agyekumhene&apos; s presentation.
                </p>
                <p className="article-par">
                  On June 7, 2024, the WHRO team focused on school and community
                  engagement. The morning was dedicated to interacting with
                  students at Prampram Vocational Technical School. Dr. Andrews
                  Agyekumhene delivered a captivating presentation on the
                  ocean’s complex ecosystem, the types of organisms found in the
                  ocean, the importance of the ocean, the impact of pollution on
                  marine ecosystems, and the significance of conserving the
                  marine environment. The open discussion brought out
                  interesting topics for deliberation, suggesting that the
                  students appreciated the awareness efforts. This interaction
                  deepened the students’ understanding of the issues and
                  inspired a sense of responsibility. A dustbin was donated to
                  the school to encourage proper waste disposal. As the day
                  coincided with Greening Ghana Day, the WHRO team demonstrated
                  its support for the national agenda by presenting 20 coconut
                  seedlings to the school for planting.
                </p>
                {/* <img alt="community engagement took place in Abia" src={thumb2} className="mb-6" /> */}
                <p className="article-par">
                  That evening, the community engagement took place in Abia at
                  6:00 PM. Despite a power outage during the setup, the
                  committed WHRO team hired a generator to power the equipment.
                  Children and adults gathered at the park to participate in the
                  engagement. Dr. Agyekumhene gave a presentation on sanitation
                  and its impact on marine life and human health. He suggested
                  ways the community could help address those activities that
                  cause ocean pollution, emphasizing proper waste disposal to
                  improve community sanitation. Audiovisuals were used to
                  enhance understanding. The Q&A session allowed community
                  members to express their concerns and share ideas for reducing
                  plastic waste. Dr. Agyekumhene invited everyone to the beach
                  cleanup scheduled for the next day at 7:00 AM.
                </p>
                <img alt=" A picture of some parties that were involved in the cleanup" src={thumb3} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  A picture of some parties that were involved in the cleanup
                  (WHRO, Ecoziol/Zoomlion, and community members)
                </p>
                <p className="article-par">
                  On June 8, 2024, the beach cleanup took place. At 6:00 AM, the
                  team, representatives from partnering organizations (Coastal
                  Association of Neighborhoods, Ecoziol/Zoomlion Limited, Young
                  Reporters for the Environment, and Coliba), students,
                  fishermen, and other community members gathered at Abia Beach
                  in the Ningo-Prampram district. Upon arrival, they were met
                  with litter scattered across the shoreline. Equipped with
                  gloves, trash bags, brooms, rakes, wheelbarrows, and other
                  necessary equipment, participants spread out along the
                  shoreline to collect litter, mainly plastics. Volunteers
                  gathered and collected various types of waste, including
                  plastic bottles and cups, rubber bags, fishing nets, and wood,
                  all of which pose threats to marine life. The collected trash
                  was picked up by a Zoomlion compactor truck, showcasing proper
                  waste disposal practices. The cleanup significantly enhanced
                  the beach’s aesthetics and removed hazards to marine life.
                </p>
                <p className="article-par">
                  The event concluded with a sense of achievement and a renewed
                  commitment to protecting the ocean. Participants left with a
                  stronger understanding of how their actions affect marine life
                  and how they can improve by practicing proper waste disposal
                  techniques and advocating for the ocean&apos;s health.
                </p>
                <div className="flex justify-center pt-10">
                  <Link to="/beach-cleanup-gallery">
                    <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                      View Gallery
                    </button>
                  </Link>
                </div>
              </div>

              <div className="flex pt-4 gap-x-5 text-green-900 md:mt-10">
                <a href="https://www.facebook.com/whroghana/" target="_blank">
                  <img alt="Facebook icon" className="h-5" src={facebook} />
                </a>
                <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                  <img alt="Twitter icon" className="h-5" src={twitter} />
                </a>
                <a href="https://www.instagram.com/whroghana/" target="_blank">
                  <img alt="Instagram icon" className="h-5" src={instagram} />
                </a>
              </div>
              <div className="comments mt-20">
                <h1 className="text-2xl mb-5">Leave a comment</h1>
                <form>
                  <textarea
                    className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                    placeholder="Comments*"
                  ></textarea>
                  <div className="flex box-border gap-x-10">
                    <div className="w-1/2">
                      <input
                        type="text"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Full Name*"
                      />
                    </div>
                    <div className="w-1/2">
                      <input
                        type="email"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full xl:w-[30%] xl:pr-10 box-border">
              {/* <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog7">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div> */}
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="Sea Turtles" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="Sea Turtle Conservation in Ghana" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="Ms. Delali Betty Dordzi Speaks" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog5">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog">
                  <div className="w-1/4">
                    <img alt="2nd Ghana National Sea Turtle Conference 2023" src={sthumb1} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      2nd Ghana National Sea Turtle Conference 2023
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog7;
