import Accordion from "./Accordion";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Faq() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div className="faq-section pt-14 md:pt-16 lg:pt-32">
        <div className="container">
          <div className="flex flex-col lg:flex-row md:px-5">
            <div
              data-aos="fade-right"
              className="w-full lg:w-1/3 md:px-5 lg:px-10"
            >
              <h6 className="text-2xl md:text-3xl font-semibold mb-5">
                How can we save the environment
              </h6>
              <p className="mb-10">
                We can save the environment by working with all relevant
                stakeholders to protect, restore, and sustain our natural
                resources through solutions-oriented approaches including
                research, consultation, education, partnerships, and
                community-based projects, and individuals and organizations can
                contact us to collaborate and work together towards this common
                goal.
              </p>
              <Link to="/contact">
                <button
                  data-aos="fade-up"
                  data-aos-delay="100"
                  className="py-3 px-6 bg-green-800 text-white hover:bg-green-700 hover:text-footer-white rounded-3xl"
                >
                  CONTACT US
                </button>
              </Link>
            </div>
            <div
              data-aos="fade-left"
              className="w-full mt-10 lg:mt-0 lg:w-2/3 md:px-5 lg:px-10"
            >
              <Accordion
                title={"What is WHRO and what does it do?"}
                content={
                  "WHRO, short for Wildlife and Human Resources Organisation, is a non-profit organization focused on building capacity for natural resources protection and conservation globally. Our core mandate is to improve natural resources and human capacity for a sustainable environment."
                }
              />
              <Accordion
                title={"What is WHRO's mission and vision?"}
                content={
                  "Our mission is to work with all relevant stakeholders to protect, restore, and sustain the environment by promoting solutions through research, consultation, education, and partnerships. Our vision is to inspire people to achieve a healthy environment for enhanced economic and socio-cultural development."
                }
              />
              <Accordion
                title={"Does WHRO offer any programs for youth involvement?"}
                content={
                  "Yes, WHRO has initiatives like the Young Scientist Programme aimed at empowering youth in STEM fields through experiential learning and field-based activities."
                }
              />
              <Accordion
                title={"What services does WHRO offer?"}
                content={
                  "WHRO offers a range of services including training and capacity building, biodiversity monitoring, environmental monitoring, habitat restoration, and ecosystem conservation."
                }
              />
              <Accordion
                title={"How can I get involved with WHRO?"}
                content={
                  "You can get involved with WHRO by volunteering, interning, donating, or contacting us directly for more information."
                }
              />
              <Accordion
                title={
                  "How can I contact WHRO for more information or to collaborate?"
                }
                content={
                  "You can contact WHRO via phone at +233 30 255 4732 or +233 53 513 7979, or via email at info@whroghana.org. You can also visit our office located at 8th Aligo Street, East Legon, P.O Box LG 485, Legon."
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
