import TeamSlider from "../components/TeamSlider";
import samuel from "../assets/images/team/samuel.jpg";

function TeamProfile2() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={samuel}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Samuel Quaatey
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">DIRECTOR</h5>
            <p className="mb-5 text-justify">
              Mr. Samuel Nii Kpakpa Quaatey holds M.Sc. (Fisheries Biology and
              Management) from the University of Wales, Bangor, UK and a BSc
              (Hons) degree in Zoology (Option Fisheries), from the University
              of Ghana, Legon, Ghana.
            </p>
            <p className="mb-5 text-justify">
              Mr. Quaatey has thirty-seven (37) years practical and relevant
              professional experience in the field of fisheries management,
              biological studies of fish species, stock assessment and law
              enforcement. He worked with the Ghana Fisheries Department
              (Commission) from October 1979 to June 2017 and was the Director
              of the Ghana Fisheries Commission from July 2009 to June 2017.
            </p>
            <p className="mb-5 text-justify">
              He has participated in both local and international research
              cruises for the assessment of fish stocks in Ghanaian waters and
              also in the Gulf of Guinea. The results of the surveys have been
              used by the Fisheries Committee of the Eastern Central Atlantic
              (CECAF)Working Groups to recommend management measures for the
              sustainable management of the fishery resources in the region.
            </p>
            <p className="mb-5 text-justify">
              Mr Quaatey was the National Fisheries Expert For the Guinea
              Current Large Marine Ecosystem Project (UNIDO/UNEP/FAO) June 2004
              to August 2008. During the period he served as the Regional
              Coordinator of the GCLME/FAO/IMR Fisheries Resources Survey in the
              Gulf of Guinea in May 2005 – July 2005, April 2006 – July 2006,
              May 2007 – July 2007 using the Norwegian research vessel, Dr.
              Fridtjof Nansen.
            </p>
            <p className="mb-5 text-justify">
              Mr. Quaatey for many years served on Working Groups of
              International Organizations such as the Fisheries Committee For
              The West Central Gulf of Guinea (FCWC), CECAF of the Food &
              Agriculture Organization (FAO), African Union (AU), International
              Commission for the Conservation of Atlantic Tunas(ICCAT) and the
              Ministerial Conference on Fisheries for African Countries
              Bordering the Atlantic Ocean (ATLAFCO) and also participated in
              Annual Meetings of the aforementioned International organizations.
            </p>
            <p className="mb-5 text-justify">
              During his tenure as Director, the Fisheries Commission was
              involved in restructuring the Organization resulting in the
              development of the Scheme of Service for the Organization.
              Parliament enacted the following during is tenure as Director,
              Fisheries Commission for sustainable management of the fisheries
              resource: Fisheries Regulations, 2010 (LI1968); Fisheries
              (Amendment) Act 2014 (Act 880); Fisheries (Amendment) Regulations,
              2015 (LI2217) and National Premix Fuel Committee Regulations, 2016
              (LI2233). In addition the following were achieved during is term
              of office as Director, Fisheries Commission: Adoption of National
              Plan of Action to prevent, deter and eliminate illegal, unreported
              and unregulated fishing for Ghana, a Fisheries Management Plan
              (2015 -2019) and establishment of Vessel Monitoring system (VMS).
            </p>
            <p className="mb-5 text-justify">
              In 2021, Mr. Quaatey was appointed and is currently serving as the
              Technical Advisor to the Minister of Fisheries and Aquaculture
              Development. He has published several articles and papers.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile2;
