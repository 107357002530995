// import { Link } from "react-router-dom";
// import turtles from "../assets/images/turtles.jpg";
// import internship from "../assets/images/internship2.png";
import s from "../assets/images/support/7.jpg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function CommunitySupportProgramme() {
  return (
    <>
      <MetaTags
        title="Programme Page - WHRO"
        keywords="Programme, Community Support, Mothers, Children"
        canonical="https://www.whroghana.org/community-programme"
      />
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16">
          <div className="container">
            <div className="ll:px-48">
              <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10 lg:gap-y-20">
                <div className="flex w-full md:px-5 justify-center">
                  <div>
                    <h3 className="mb-10 text-center text-2xl md:text-4xl font-semibold md:min-h-[56px] xl:min-h-0 text-green-800">
                      Community Support Programme
                    </h3>
                    <img className="w-3/4 mx-auto" src={s} />
                    <p className="mt-10">
                      WHRO has over the years contributed to the socio-economic
                      development of several communities, especially those
                      communities within which it operates by introducing
                      diverse income-generating alternatives and additional
                      livelihoods. Drawing from the vast experience of its core
                      team in community issues, WHRO has provided livelihood
                      training for communities to help promote economic
                      development of the beneficiary communities. Some
                      communities have also been trained in how to enhance their
                      existing livelihoods so as to increase their profits and
                      improve their economic status. WHRO has also provided
                      diverse forms of aids to some elected communities. The
                      organisation continues to seek support to provide economic
                      benefits in communities challenged with poverty.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-10 md:pt-20">
                <Link to="/community-support-gallery">
                  <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                    View Gallery
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CommunitySupportProgramme;
