import { Link } from "react-router-dom";
// import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import team from "../assets/images/worker1.jpg";
// import team2 from "../assets/images/worker.jpg";
import turtles from "../assets/images/turtles.jpg";
import internship from "../assets/images/internship2.png";
import ysp from "../assets/images/ysp.jpg";
import m from "../assets/images/mangrove/7.png";
import s from "../assets/images/com_support.jpg";
import ed1 from "../assets/images/eaa/19.jpg";

function ProgramSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="relative pb-6 md:pb-12 box-border">
        <div className="container">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/sea-turtles-programme"}>
                  <img alt="Sea Turtles Conservation Programme" src={turtles} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Sea Turtles Conservation Programme
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/ysp"}>
                  <img alt="Young Scientists Programme" src={ysp} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Young Scientists Programme
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/internship-programme"}>
                  <img alt="Volunteer & Internship Progammes" src={internship} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Volunteer & Internship Progammes
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/mangrove-conservation"}>
                  <img alt="Mangrove Conservation" src={m} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Mangrove Conservation
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/community-programme"}>
                  <img alt="Community Support" src={s} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Community Support
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/education-gallery"}>
                  <img alt="Education and Awareness" src={ed1} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Education and Awareness
                    </h4>
                  </div>
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProgramSlider;
