import { Link } from "react-router-dom";
import biodiversity from "../assets/images/biodiversity.jpg";
import environmental from "../assets/images/environmental.png";
import habitat from "../assets/images/habitat.png";
// import internship from "../assets/images/internship1.jpg";
import monitoring from "../assets/images/monitoring.jpg";
// import ysp from "../assets/images/ysp.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function ServicesDisplay() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <div id="services" className="pt-14 md:pt-16 lg:pt-32">
        <div className="container">
          <div data-aos="fade-down">
            <h6 className="text-center text-xs md:text-sm mini-heading mb-2">
              EXPLORE OUR OFFERINGS
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Our Services
            </h3>
          </div>
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-10 flex-wrap box-border justify-center lg:justify-between lg:px-4">
              {/* <div className="service-card bg-green-200">
                <img alt="Internship & volunteering" src={internship} className="w-full" />
                <div className="p-5">
                  <h4 className="text-lg md:text-xl font-semibold text-black">
                    Internship & volunteering
                  </h4>
                </div>
              </div>
              <div className="service-card bg-green-200">
                <img alt="Young Scientist Programme" src={ysp} className="w-full" />
                <div className="p-5">
                  <h4 className="text-lg md:text-xl font-semibold text-black">
                    Young Scientist Programme
                  </h4>
                </div>
              </div> */}
              <div
                data-aos="zoom-in"
                data-aos-delay="150"
                className="service-card bg-green-200"
              >
                <img alt="Training & Capacity Building" src={monitoring} className="w-full" />
                <div className="p-5">
                  <h4 className="text-lg md:text-xl font-semibold text-black">
                    Training & Capacity Building
                  </h4>
                </div>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-delay="150"
                className="service-card bg-green-200"
              >
                <img alt="Biodiversity Monitoring" src={biodiversity} className="w-full" />
                <div className="p-5">
                  <h4 className="text-lg md:text-xl font-semibold text-black">
                    Biodiversity Monitoring
                  </h4>
                </div>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-delay="150"
                className="service-card bg-green-200"
              >
                <img alt="Environmental Monitoring" src={environmental} className="w-full" />
                <div className="p-5">
                  <h4 className="text-lg md:text-xl font-semibold text-black">
                    Environmental Monitoring
                  </h4>
                </div>
              </div>
              <div
                data-aos="zoom-in"
                data-aos-delay="150"
                className="service-card bg-green-200"
              >
                <img alt="Habitat Restoration & Species Conservation" src={habitat} className="w-full" />
                <div className="p-5">
                  <h4 className="text-lg md:text-xl font-semibold text-black">
                    Habitat Restoration & Species Conservation
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center pt-10 md:pt-20">
            <Link to="/services">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                More About Our Services
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesDisplay;
