// import biodiversity from "../assets/images/biodiversity.png";
// import environmental from "../assets/images/environmental.png";
import internship from "../assets/images/internship2.png";
import cleanup from "../assets/images/cleanup.jpg";
import turtles from "../assets/images/turtles.jpg";
import ysp8 from "../assets/images/ysp/11.jpg";
import ed1 from "../assets/images/eaa/19.jpg";
import ab25 from "../assets/images/sb/25.jpg";
import v15 from "../assets/images/vultures/15.jpg";
import environmental from "../assets/images/environmental.png";
import m2 from "../assets/images/mangrove/2.png";
import t7 from "../assets/images/training/7.png";
import s13 from "../assets/images/support/13.jpg";
import fb7 from "../assets/images/fb/7.jpg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";
import Header from "../components/Header";
import header from "../assets/images/news.jpg";

function GalleryList() {
  return (
    <>
      <MetaTags
        title="Gallery List - WHRO"
        keywords="Gallery"
        canonical="https://www.whroghana.org/gallery-list"
      />
      <Header
        img={header}
        subHeading="GALLERY LIST"
        mainHeading="Relive All Our Stories"
      />
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-10 flex-wrap box-border justify-center lg:justify-start lg:px-9">
              <div className="gl-card">
                <Link to="/beach-cleanup-gallery">
                  <img src={cleanup} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">Beach Cleanup</p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to={"/ysp-gallery"}>
                  <img src={ysp8} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Young Scientists Program
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/education-gallery">
                  <img src={ed1} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Education and Awareness
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/community-support-gallery">
                  <img src={s13} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">Community Support</p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/interns-gallery">
                  <img src={internship} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Interns & Volunteers
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/training-gallery">
                  <img src={t7} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Training & Capacity Building
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/e-monitoring-gallery">
                  <img src={environmental} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Environmental Monitoring
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/shore-birds-gallery">
                  <img src={ab25} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">Aquatic Birds</p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/vultures-gallery">
                  <img src={v15} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">Vultures</p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/sea-turtles-gallery">
                  <img src={turtles} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Sea Turtle Conservation
                    </p>
                  </div>
                </Link>
              </div>

              <div className="gl-card">
                <Link to="/mangrove-gallery">
                  <img src={m2} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">
                      Mangrove Conservation
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gl-card">
                <Link to="/forest-birds-gallery">
                  <img src={fb7} className="w-full" />
                  <div className="text-overlay absolute bottom-[-100%] w-full text-white p-5">
                    <p className="text-lg font-semibold">Forest Birds</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryList;
