// import { Link } from "react-router-dom";
// import turtles from "../assets/images/turtles.jpg";
// import internship from "../assets/images/internship2.png";
import m from "../assets/images/mangrove/7.png";
import { Link } from "react-router-dom";

function MangroveProgramme() {
  return (
    <>
      {/* <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-3 text-white"
              >
                EVERYTHING WE RUN
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                All About Our Programmes
              </h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16 lg:pt-32">
          <div className="container">
            <div className="ll:px-48">
              <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10 lg:gap-y-20">
                <div className="flex w-full md:px-5 justify-center">
                  <div>
                    <h3 className="mb-10 text-center text-2xl md:text-4xl font-semibold md:min-h-[56px] xl:min-h-0 text-green-800">
                      Mangrove Conservation Programme
                    </h3>
                    <img className="w-3/4 mx-auto" src={m} />
                    <p className="mt-10">
                      Mangroves are one of the most biodiverse ecosystems around
                      the globe providing a number of important ecological
                      services and socio-economic goods both for communities.
                      Over the years, mangroves have experienced increasing
                      degradation around the globe especially in Africa and
                      particularly in Ghana. One of the critical threats to
                      mangroves is deforestation resulting form over-cutting
                      which largely occurs at the local level, by coastal
                      communities who require fuel wood for domestic use
                      (cooking) and smoking fish; Most local communities have no
                      alternative than to heavily rely on locally available
                      natural materials to produce energy. Mangroves are also
                      harvested for building materials, brush park fishing, and
                      for charcoal production. Large areas of mangroves are also
                      cleared to make way for aquaculture. Encroaching urban
                      development, unsustainable shrimp farming and global
                      climatic change also negatively impact the health of
                      mangrove ecosystems.
                    </p>
                    <p className="mt-2">
                      Mangrove experts at WHRO contribute to mangrove
                      conservation in Ghana by providing technical advice and
                      building capacities of local communities and private
                      institutions (NGOs, CSOs, etc) to restore degraded areas
                      of mangroves and persevere existing health forests. Our
                      training covers important areas such as mangrove nursery
                      establishment, operation, and mangrove seedling
                      production. Our approach includes both classroom and
                      field-based training. WHRO experts also conduct research
                      to explore local utilization, threats and assessed
                      existing conservation practices in Ghana, and develop
                      dada-driven approaches to mangrove conservation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-10 md:pt-20">
                <Link to="/mangrove-gallery">
                  <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                    View Gallery
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MangroveProgramme;
