// import React from "react";
import andrews from "../assets/images/team/andrews.jpg";
import samuel from "../assets/images/team/samuel.jpg";
import delali from "../assets/images/team/delali.jpg";
import stephen from "../assets/images/team/stephen.jpg";
import sly from "../assets/images/team/sly.jpg";
import christina from "../assets/images/team/christina.jpg";
import laila from "../assets/images/team/laila.jpg";
import james from "../assets/images/team/james.jpg";
import jannah from "../assets/images/team/jannah.jpg";
import dede from "../assets/images/team/dede.jpg";
import sam from "../assets/images/team/sam.jpg";
import kesse from "../assets/images/team/kesse.jpg";
import { Link } from "react-router-dom";

function TeamGrid() {
  return (
    <div>
      <h6 className="text-center main-heading font-medium text-2xl md:text-4xl mb-14 text-green-950">
        Core Team
      </h6>
      <div className="flex md:px-9 flex-container flex-wrap justify-between">
        <div className="team-img">
          <Link to="/team-profile/1">
            <div className="">
              <img alt="Dr. Andrews Agyekumhene" className="mx-auto mb-6" src={andrews} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Dr. Andrews Agyekumhene
              </h6>
              <p className="text-sm">EXECUTIVE DIRECTOR</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/2">
            <div className="">
              <img alt="Samuel Quaatey" className="mx-auto mb-6" src={samuel} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Samuel Quaatey
              </h6>
              <p className="text-sm">DIRECTOR</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/4">
            <div className="">
              <img alt="Stephen Nassam" className="mx-auto mb-6" src={stephen} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Stephen Nassam
              </h6>
              <p className="text-sm">FINANCE MANAGER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/3">
            <div className="">
              <img alt="Delali Betty Dordzi" className="mx-auto mb-6" src={delali} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Delali Betty Dordzi
              </h6>
              <p className="text-sm">PROGRAMS MANAGER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/9">
            <div className="">
              <img alt="Jannatul Firdaus" className="mx-auto mb-6" src={jannah} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Jannatul Firdaus
              </h6>
              <p className="text-sm">PROGRAMME COORDINATOR</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/11">
            <div className="">
              <img alt="Jennifer Dede Ankrah" className="mx-auto mb-6" src={dede} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Jennifer Dede Ankrah
              </h6>
              <p className="text-sm">MEDIA & COMMUNICATION OFFICER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/12">
            <div className="">
              <img alt="Sampson Mawuko Kudior" className="mx-auto mb-6" src={sam} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Sampson Mawuko Kudior
              </h6>
              <p className="text-sm">COMMUNITY ENGAGEMENT & OUTREACH OFFICER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/10">
            <div className="">
              <img alt="Emmanuel Kesse" className="mx-auto mb-6" src={kesse} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Emmanuel Kesse
              </h6>
              <p className="text-sm">CLIMATE CHANGE OFFICER</p>
            </div>
          </Link>
        </div>
      </div>
      <h6 className="text-center main-heading font-medium text-2xl md:text-4xl my-14 text-green-950">
        Board of Directors
      </h6>
      <div className="flex md:px-9 flex-container flex-wrap justify-between">
        <div className="team-img">
          <Link to="/team-profile/5">
            <div className="">
              <img alt="Sylvester Ofosu-Gyeabour" className="mx-auto mb-6" src={sly} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Sylvester Ofosu-Gyeabour
              </h6>
              <p className="text-sm">BOARD MEMBER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/6">
            <div className="">
              <img alt="Dr. Christina Appiah-Nimo" className="mx-auto mb-6" src={christina} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Dr. Christina Appiah-Nimo
              </h6>
              <p className="text-sm">BOARD MEMBER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/7">
            <div className="">
              <img alt="Dr. Laila Gifty Akita" className="mx-auto mb-6" src={laila} />
              <h6 className="text-xl md:text-2xl font-semibold">
                Dr. Laila Gifty Akita
              </h6>
              <p className="text-sm">BOARD MEMBER</p>
            </div>
          </Link>
        </div>
        <div className="team-img">
          <Link to="/team-profile/8">
            <div className="">
              <img alt="James Biney" className="mx-auto mb-6" src={james} />
              <h6 className="text-xl md:text-2xl font-semibold">James Biney</h6>
              <p className="text-sm">BOARD MEMBER</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default TeamGrid;
