// import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import team from "../assets/images/worker1.jpg";
// import team2 from "../assets/images/worker.jpg";
import louisa from "../assets/images/testimonials/louisa.jpg";
import comfort from "../assets/images/testimonials/comfort.jpg";
import sampson from "../assets/images/testimonials/sampson.jpg";
import sylvia from "../assets/images/testimonials/sylvia.jpg";
import jennifer from "../assets/images/testimonials/test_1.jpg";
import samuel from "../assets/images/testimonials/test_2.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function TestimonialSlider() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div data-aos="zoom-in" className="relative pb-12 md:pb-0 box-border">
        <div className="container">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="p-3 flex flex-col h-full justify-center">
                <div className="h-full testimonial-card p-5 rounded-md">
                  <div className="flex flex-col md:flex-row mb-3">
                    <img alt="Louisa Miboshierega Ogundare" className="mb-6 w-11 rounded-full mr-3" src={louisa} />
                    <div>
                      <h6 className="font-semibold">
                        Louisa Miboshierega Ogundare
                      </h6>
                      <p className="text-sm">STUDENT - UNIVERSITY OF GHANA</p>
                    </div>
                  </div>
                  <p>
                    "My internship at the Wildlife and Human Resources
                    Organization (WHRO) was an enlightening experience. It
                    presented great opportunities to practically experience
                    things I formerly learned in class and improved my
                    understanding on other organizations and laws that deal with
                    preserving the environment and managing natural resources. I
                    believe the internship also opened my mind to the various
                    occupations available to me as a Marine Science major."
                  </p>
                </div>
              </div>
              <div className="p-3 flex flex-col justify-center">
                <div className="h-full testimonial-card p-5 rounded-md">
                  <div className="flex flex-col md:flex-row">
                    <img alt="Comfort Gli"
                      className="mb-6 w-11 rounded-full mr-3"
                      src={comfort}
                    />
                    <div>
                      <h6 className="font-semibold">Comfort Gli</h6>
                      <p className="text-sm">STUDENT - UNIVERSITY OF GHANA</p>
                    </div>
                  </div>
                  <p>
                    "The Wildlife and Human Resources Organisation (WHRO) has
                    taught me more about marine organisms, such as sea turtles,
                    and ways to protect these organisms. WHRO has helped me
                    inspire the environment wherever I go with the knowledge I
                    gained from my internship with them."
                  </p>
                </div>
              </div>
              <div className="p-3 flex flex-col justify-center">
                <div className="h-full testimonial-card p-5 rounded-md">
                  <div className="flex flex-col md:flex-row">
                    <img alt="Sampson Mawuko Kudior"
                      className="mb-6 w-11 rounded-full mr-3"
                      src={sampson}
                    />
                    <div>
                      <h6 className="font-semibold">Sampson Mawuko Kudior</h6>
                      <p className="text-sm">STUDENT - UNIVERSITY OF GHANA</p>
                    </div>
                  </div>
                  <p>
                    "As a former intern at Wildlife and Human Resources
                    Organisation (WHRO), I can state that the organization has
                    given me a life-changing opportunity that combines
                    environmental impact, skill development, and a passion for
                    the planet's creatures. The organization also provides me
                    with the skills and information I need to pursue a
                    fulfilling career in wildlife conservation, enabling me to
                    play a vital part in safeguarding endangered species."
                  </p>
                </div>
              </div>
              <div className="p-3 flex h-full flex-col justify-center">
                <div className="h-full testimonial-card p-5 rounded-md">
                  <div className="flex flex-col md:flex-row">
                    <img alt="Sylvia Akosua Tinkoramah" className="mb-6 w-11 rounded-full mr-3" src={sylvia} />
                    <div>
                      <h6 className="font-semibold">
                        Sylvia Akosua Tinkoramah
                      </h6>
                      <p className="text-sm">STUDENT - UNIVERSITY OF GHANA</p>
                    </div>
                  </div>
                  <p>
                    "I completed my internship at Wildlife Human Resources
                    Organisation (WHRO), and it was truly amazing. I learned so
                    many new things about the environment and the corporate
                    world. Additionally, I had the opportunity to join a two-day
                    field trip during which I got to see migratory birds,
                    different kinds of mangroves, and visited a salt production
                    company. My internship at WHRO was genuinely enlightening
                    and educational. I was fortunate to have the best
                    supervisors who ensured that we were always okay, helped us
                    learn new things, and showered us with love."
                  </p>
                </div>
              </div>
              <div className="p-3 flex h-full flex-col justify-center">
                <div className="h-full testimonial-card p-5 rounded-md">
                  <div className="flex flex-col md:flex-row">
                    <img alt="Jennifer Dede Ankrah"
                      className="mb-6 w-11 rounded-full mr-3"
                      src={jennifer}
                    />
                    <div>
                      <h6 className="font-semibold">Jennifer Dede Ankrah</h6>
                      <p className="text-sm">STUDENT - UNIVERSITY OF GHANA</p>
                    </div>
                  </div>
                  <p>
                    " I am a student of the University of Ghana, Legon. I had
                    the most incredible time at WHRO undertaking my industrial
                    internship. It was an amazing opportunity for me to work
                    closely with seasoned and passionate professionals who are
                    dedicated to protecting and preserving our precious
                    wildlife. I gained valuable hands-on experience in planning
                    and executing scientific research, conservation awareness,
                    community engagement, and field monitoring of wildlife
                    species such as aquatic birds and sea turtles. This
                    internship did not only deepen my knowledge and love for
                    wildlife, but also helped equip me with valuable skills that
                    will doubtlessly help me in the field of conservation. I am
                    extremely grateful for this unforgettable experience."
                  </p>
                </div>
              </div>
              <div className="p-3 flex h-full flex-col justify-center">
                <div className="h-full testimonial-card p-5 rounded-md">
                  <div className="flex flex-col md:flex-row">
                    <img alt="Samuel Fiifi Simons" className="mb-6 w-11 rounded-full mr-3" src={samuel} />
                    <div>
                      <h6 className="font-semibold">Samuel Fiifi Simons</h6>
                      <p className="text-sm">NATIONAL SERVICE PERSONNEL</p>
                    </div>
                  </div>
                  <p>
                    " Working with Wildlife and Human Resource Organization
                    (WHRO) Ghana has been an enriching experience. I was
                    entrusted with diverse responsibilities that accelerated my
                    growth in natural resource conservation and corporate
                    skills. WHRO Ghana provided invaluable exposure to
                    collecting, analysing, and reporting scientific data,
                    enhancing my abilities in data management and
                    interpretation. The supportive environment and challenging
                    opportunities enabled me to realize my hidden potentials and
                    contribute meaningfully to our research endeavours. It was
                    an honour to serve with WHRO Ghana during my national
                    service period. I am grateful for the trust and guidance
                    extended to me and proud to have been part of such a
                    dedicated team committed to the conservation of Ghana&apos;s
                    wildlife and natural resources. "
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
export default TestimonialSlider;
