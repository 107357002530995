import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import b1 from "../assets/images/beach_cleanup/1.jpg";
import b2 from "../assets/images/beach_cleanup/2.jpg";
import b3 from "../assets/images/beach_cleanup/3.jpg";
import b4 from "../assets/images/beach_cleanup/4.jpg";
import b5 from "../assets/images/beach_cleanup/5.jpg";
import b6 from "../assets/images/beach_cleanup/6.jpg";
import b7 from "../assets/images/beach_cleanup/7.jpg";
import b8 from "../assets/images/beach_cleanup/8.jpg";
import b9 from "../assets/images/beach_cleanup/9.jpg";
import b10 from "../assets/images/beach_cleanup/10.jpg";
import b11 from "../assets/images/beach_cleanup/11.jpg";
import b12 from "../assets/images/beach_cleanup/12.jpg";
import b13 from "../assets/images/beach_cleanup/13.jpg";
import b14 from "../assets/images/beach_cleanup/14.jpg";
import b15 from "../assets/images/beach_cleanup/15.jpg";
import b16 from "../assets/images/beach_cleanup/16.jpg";
import b17 from "../assets/images/beach_cleanup/17.jpg";
import b18 from "../assets/images/beach_cleanup/18.jpg";
import b19 from "../assets/images/beach_cleanup/19.jpg";
import b20 from "../assets/images/beach_cleanup/20.jpg";

// Define the array of images here
const photos = [
  { src: b1, width: 1000, height: 667 },
  { src: b2, width: 1000, height: 667 },
  { src: b3, width: 1000, height: 667 },
  { src: b4, width: 1000, height: 667 },
  { src: b5, width: 1000, height: 667 },
  { src: b6, width: 1000, height: 667 },
  { src: b7, width: 1000, height: 667 },
  { src: b8, width: 1000, height: 667 },
  { src: b9, width: 1000, height: 667 },
  { src: b10, width: 1000, height: 667 },
  { src: b11, width: 1000, height: 667 },
  { src: b12, width: 1000, height: 667 },
  { src: b13, width: 1000, height: 667 },
  { src: b14, width: 1000, height: 667 },
  { src: b15, width: 1000, height: 667 },
  { src: b16, width: 1000, height: 667 },
  { src: b17, width: 1000, height: 667 },
  { src: b18, width: 1000, height: 667 },
  { src: b19, width: 1000, height: 667 },
  { src: b20, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function BeachCleanupGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Beach Cleanup
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
