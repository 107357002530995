// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import turtles from "../assets/images/stcprog.jpg";

function SeaTurtleProgramme() {
  return (
    <>
      {/* <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-3 text-white"
              >
                EVERYTHING WE RUN
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                All About Our Programmes
              </h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16">
          <div className="container">
            <div className="ll:px-48">
              <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10 lg:gap-y-20">
                <div className="flex w-full md:px-5 justify-center">
                  <div>
                    <h3 className="mb-10 text-center text-2xl md:text-4xl font-semibold md:min-h-[56px] xl:min-h-0 text-green-800">
                      Sea Turtles Conservation Programme
                    </h3>
                    <img className="w-3/4 mx-auto" src={turtles} />
                    <p className="mt-10">
                      Ghana has approximately 550 km of coastline that provides
                      significant foraging and nesting habitats for five species
                      of marine turtles, including the critically endangered
                      hawksbill turtle. Sea turtles in Ghana face a significant
                      number of anthropogenic mortality including: Ghana has
                      approximately 550 km of coastline that provides
                      significant foraging and nesting habitats for five species
                      of marine turtles, including the critically endangered
                      hawksbill turtle. Sea turtles in Ghana face a significant
                      number of anthropogenic mortality including (a) intensive
                      near-shore fishery bycatch, (b) direct harvest of nesting
                      individuals and eggs, (c) increased nest predation by dogs
                      and pig, (d) degradation of nesting habitat along the
                      coast of Ghana and (e) negative consequences of emerging
                      unregulated ecotourism activities.
                    </p>
                    {/* <ul className="pl-2 my-2">
                      <li className="mt-2">
                        <span className="text-green-800">(a)</span> Awareness
                        creation
                      </li>
                      <li className="mt-2">
                        <span className="text-green-800">(b)</span> Research and
                        capacity building
                      </li>
                      <li className="mt-2">
                        <span className="text-green-800">(c)</span> Livelihood
                        support programmes
                      </li>
                      <li className="mt-2">
                        <span className="text-green-800">(d)</span> Habitat
                        quality improvement
                      </li>
                      <li className="mt-2">
                        <span className="text-green-800">(e)</span> By-catch
                        reduction programmes
                      </li>
                    </ul> */}
                    <p>
                      These activities continue to increase due to increasing
                      pressure on Ghana’s coastal resources.
                    </p>
                    <p className="mt-2">
                      WHRO works with all relevant stakeholders to address the
                      many threats and challenges that sea turtles face in the
                      coastal waters of Ghana. Our sea turtle conservation
                      strategies include:
                    </p>
                    <ul className="list-disc pl-5">
                      <li className="mt-2">Awareness creation</li>
                      <li className="mt-2">Research and capacity building</li>
                      <li className="mt-2">Livelihood support programmes</li>
                      <li className="mt-2">Habitat quality improvement</li>
                      <li className="mt-2">By-catch reduction programmes</li>
                      <li className="mt-2">Stakeholders’ engagement</li>
                      <li className="mt-2">
                        Youth empowerment through field-based activities
                      </li>
                      <li className="mt-2">
                        Volunteer and internship programmes
                      </li>
                      <li className="mt-2">Policy support and advocacy</li>
                    </ul>
                    <p className="mt-2">
                      WHRO continues to promote sea turtle conservation in all
                      coastal communities in Ghana through active participation
                      by local communities.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-10 md:pt-20">
              <Link to="/sea-turtles-gallery">
                <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                  View Gallery
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SeaTurtleProgramme;
