// import { Link } from "react-router-dom";
// import turtles from "../assets/images/turtles.jpg";
// import internship from "../assets/images/internship2.png";
import ysp from "../assets/images/ysp/11.jpg";
import { Link } from "react-router-dom";

function YSProgrammes() {
  return (
    <>
      {/* <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-3 text-white"
              >
                EVERYTHING WE RUN
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                All About Our Programmes
              </h3>
            </div>
          </div>
        </div>
      </div> */}
      <div className="pb-14 md:pb-16 lg:pb-32">
        <div className="projects-display pt-14 md:pt-16 lg:pt-32">
          <div className="container">
            <div className="ll:px-48">
              <div className="flex flex-col md:flex-row lg:px-5 flex-wrap justify-center gap-y-10 lg:gap-y-20">
                <div className="flex w-full md:px-5 justify-center">
                  <div>
                    <h3 className="mb-10 text-center text-2xl md:text-4xl font-semibold md:min-h-[56px] xl:min-h-0 text-green-800">
                      The Young Scientist Programme
                    </h3>
                    <img className="w-3/4 mx-auto" src={ysp} />
                    <p className="mt-10">
                      The phrase “catch them young and they shall be yours
                      forever” appear to be an old cliché haven been used in its
                      different forms to explain many situations. Though the
                      origin of this important phrase appears unknown, perhaps
                      it takes its roots from the good book, the Holy Bible
                      which admonishes us to bring up the child in a way he/she
                      should go and when he/she grows he/she will not depart
                      from it (Proverbs 23:13). It is against this background
                      that WHRO is introducing the YOUNG SCIENTISTS programme to
                      help inculcate the desire to pursue Science, Technology,
                      Engineering and Mathematics (STEM) in the youth.
                    </p>
                    <p className="mt-2">
                      The main aim of the Young Scientists Programme is to help
                      empower and build the capacity of upcoming future
                      generation in the field of STEM with the hope of creating
                      a community of youth enthusiastic about STEM and its
                      application in real life. By introducing the youth to
                      experiential learning through field-based activities, WHRO
                      hopes to empower the youth with analytical, critical
                      thinking and science-based decision-making skills which
                      will help contribute to the future development of the
                      country. By improving science literacy in the youth, the
                      program will also help develop essential skills such as
                      communication, public speaking and teamwork among the
                      youth which will contribute to their success in future.
                    </p>
                    <p className="mt-2">
                      The Young Scientists programme was launched on the 8th
                      June 2022 to coincide with the World’s Ocean Day
                      celebration. The programme was held at Prampram Senior
                      High school during which the a team from WHRO, Department
                      of Marine and Fisheries Sciences and Fisheries Commission
                      interacted with students about the environment in general
                      with focus on the Ocean. Topics like Benefits of the
                      ocean, pollutions and Oil and Gas were discussed. To
                      promote sanitation among the students, WHRO donated
                      dustbins and assorted trees to the school. WHRO intends to
                      keep this momentum by replicating such programmes in all
                      the schools along the coast of Ghana.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-10 md:pt-20">
                <Link to="/ysp-gallery">
                  <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                    View Gallery
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default YSProgrammes;
