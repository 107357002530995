import TeamSlider from "../components/TeamSlider";
import andrews from "../assets/images/team/andrews.jpg";

function TeamProfile1() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={andrews}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Dr. Andrews Agyekumhene
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              EXECUTIVE DIRECTOR
            </h5>
            <p className="mb-5 text-justify">
              Dr. Andrews Agyekumhene is an environmental and natural resources
              expert with extensive experience in integrated coastal zone
              management. Andrews has a BSc. Degree in Oceanography and
              Fisheries, M.Phil degree in Biological Oceanography and PhD in
              Integrated Coastal Zone Management. He trained in
              Multi-Stakeholder Engagement Processes and Curriculum Development
              at the Wageningen UR Center for Innovation Development in the
              Netherlands, and in Impact Assessment from various institutions
              including EPA-Ghana and IAIA. Andrews has in-depth knowledge of
              IFC Performance Standards and Mitigation Hierarchy.
            </p>
            <p className="mb-5 text-justify">
              He has worked with the Ghana Wildlife Division for 10 years during
              which time he successfully and effectively managed 2 of Ghana’s
              Coastal Wetlands, one of which is a Biosphere Reserve. As a
              Prosecutor for Forest and Wildlife Crimes for the Forestry
              Commission, Andrews advocated for and halted illegalities to
              Wildlife and their habitats including forest reserves in Ghana. He
              currently works at the University of Ghana as a lecturer where he
              is helping to train future conservationists in natural resources
              management.
            </p>
            <p className="mb-5 text-justify">
              Andrews is a coordinator for the longest sea turtle tagging and
              conservation project in Ghana and oversees sea turtle projects in
              all four coastal regions of Ghana where he empowers local
              communities through capacity building to conserve sea turtles. He
              serves as a member of the IUCN Marine Turtle Specialist Group.
              Andrews is a past Board Member and the current president of the
              International Sea Turtle Society (ISTS). He is a regional editor
              of the Africa Sea Turtle Newsletter which produces peer-reviewed
              articles on sea turtles in Africa bi-annually.
            </p>
            <p className="mb-5 text-justify">
              Andrews has great expertise in developing and implementation of
              conservation projects and has engaged many relevant stakeholders
              on numerous platforms which has led to the conservation of several
              flora and fauna species in many parts of Ghana. As a member of the
              International Association of Impact Assessors (IAIA-Ghana), he has
              led and conducted major consultancy works for both local and
              international organizations towards the development of
              Environmental Impact Assessments (EIAs) for projects in Energy,
              Oil and Gas, Fisheries and Port infrastructure. He has attended
              several national and international conferences and workshops and
              co-authored and published in a number of journals of international
              repute.
            </p>
            <p>
              Andrews has interest in nature documentaries, bird watching,
              hiking and swimming.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile1;
