import { Link } from "react-router-dom";
import TeamGrid from "../components/TeamGrid";
import header from "../assets/images/whro_header.jpg";
import Header from "../components/Header";

function Team() {
  return (
    <>
      <Header
        img={header}
        subHeading="OUR TEAM"
        mainHeading="Those Who Make It Possible"
      />
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <TeamGrid />
          <div className="flex justify-center">
            <Link to="/volunteer">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                Join Us Now!
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className=" md:h-96 relative bg-blue-black">
        <div className="container">
          <div className="px-4 md:px-9 relative">
            <div className=" p-8 md:py-10 md:px-14 left-0 right-0">
              <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2">
                  <h6 className="text-white text-center md:text-start text-2xl md:text-3xl font-semibold">
                    Today is the day to reach out and lend a helping hand.
                  </h6>
                </div>
                <div className="w-full mt-4 md:mt-0 md:w-1/2 flex justify-center md:justify-end">
                  <a>
                    <button className="px-10 py-3 bg-white rounded-3xl">
                      JOIN US
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Team;
