import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function DonationDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div className="don-section py-14 md:py-16 h-[500px]">
        <div className="container">
          <div className="flex">
            <div
              data-aos="fade-right"
              className="w-full md:w-2/3 lg:w-2/4 px-5 lg:pl-10 lg:pr-20"
            >
              <div data-aos="fade-down" data-aos-delay="100">
                <h6 className="text-xs md:text-sm mini-heading mb-12 text-white">
                  DONATION
                </h6>
              </div>
              <h3 className="main-heading font-medium text-2xl md:text-4xl mb-5 donation-text">
                Raise awareness and funds for the right causes
              </h3>
              <p className="mb-10 text-white">
                Your support or donation can make a tangible difference in our
                efforts to protect and conserve natural resources for the
                benefit of present and future generations.
              </p>
              <Link to={"/donate"}>
                <button
                  data-aos="fade-up"
                  data-aos-delay="100"
                  className="py-3 px-6 bg-white text-blue-black hover:bg-green-200 rounded-3xl"
                >
                  DONATE NOW
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DonationDisplay;
