import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

function MetaTags({ title, keywords, canonical }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Wildlife and Human Resources Organisation is a non-profit organization focused on building capacity for natural resources protection and conservation globally."
        />
        <meta
          name="author"
          content="Wildlife and Human Resources Organisation (WHRO)"
        />
        <meta
          name="keywords"
          content={`WHRO, Wildlife, Human Resources, Natural Resources Protection, Conservation, Environmental Sustainability, Ghana, ${keywords}`}
        />
        <link rel="canonical" href={canonical} />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content="Wildlife and Human Resources Organisation is a non-profit organization focused on building capacity for natural resources protection and conservation globally."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonical} />
        {/* <meta
          property="og:image"
          content="https://www.yoursite.com/images/og-image.jpg"
        /> */}
      </Helmet>
    </>
  );
}

MetaTags.propTypes = {
  title: PropTypes.string,
  canonical: PropTypes.string,
  keywords: PropTypes.string,
};

export default MetaTags;
