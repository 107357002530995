import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import st1 from "../assets/images/stc/1.jpg";
import st2 from "../assets/images/stc/2.jpg";
import st3 from "../assets/images/stc/3.jpg";
import st4 from "../assets/images/stc/4.jpg";
import st5 from "../assets/images/stc/5.jpg";
import st6 from "../assets/images/stc/6.jpg";
import st7 from "../assets/images/stc/7.jpg";
import st8 from "../assets/images/stc/8.jpg";
import st9 from "../assets/images/stc/9.jpg";
import st10 from "../assets/images/stc/10.jpg";
import st11 from "../assets/images/stc/11.jpg";
import st12 from "../assets/images/stc/12.jpg";
import st13 from "../assets/images/stc/13.jpg";
import st14 from "../assets/images/stc/14.jpg";
import st15 from "../assets/images/stc/15.jpg";
import st16 from "../assets/images/stc/16.jpg";
import st17 from "../assets/images/stc/17.jpg";
import st18 from "../assets/images/stc/18.jpg";
import st19 from "../assets/images/stc/19.jpg";
import st20 from "../assets/images/stc/20.jpg";
import st21 from "../assets/images/stc/21.jpg";
import st22 from "../assets/images/stc/22.jpg";
import st23 from "../assets/images/stc/23.jpg";
import st24 from "../assets/images/stc/24.jpg";
import st25 from "../assets/images/stc/25.jpg";
import st26 from "../assets/images/stc/26.jpg";
import st27 from "../assets/images/stc/27.jpg";

// Define the array of images here
const photos = [
  { src: st1, width: 1000, height: 667 },
  { src: st2, width: 1000, height: 667 },
  { src: st3, width: 1000, height: 667 },
  { src: st4, width: 1000, height: 667 },
  { src: st5, width: 1000, height: 667 },
  { src: st6, width: 1000, height: 667 },
  { src: st7, width: 1000, height: 667 },
  { src: st8, width: 1000, height: 667 },
  { src: st9, width: 1000, height: 667 },
  { src: st10, width: 1000, height: 667 },
  { src: st11, width: 1000, height: 667 },
  { src: st12, width: 1000, height: 667 },
  { src: st13, width: 1000, height: 667 },
  { src: st14, width: 1000, height: 667 },
  { src: st15, width: 1000, height: 667 },
  { src: st16, width: 1000, height: 667 },
  { src: st17, width: 1000, height: 667 },
  { src: st18, width: 1000, height: 667 },
  { src: st19, width: 1000, height: 667 },
  { src: st20, width: 1000, height: 667 },
  { src: st21, width: 1000, height: 667 },
  { src: st22, width: 1000, height: 667 },
  { src: st23, width: 1000, height: 667 },
  { src: st24, width: 1000, height: 667 },
  { src: st25, width: 1000, height: 667 },
  { src: st26, width: 1000, height: 667 },
  { src: st27, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function TurtlesGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Sea Turtle Conservation
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
