import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function ComingSoon() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <>
      <MetaTags
        title="Coming Soon - WHRO"
        keywords="Coming Soon, "
        canonical="https://www.whroghana.org/https://www.whroghana.org/coming-soon"
      />
      <div className="text-holder relative">
        <div className="flex flex-col justify-center left-0 right-0 h-screen">
          <h1 className="text-center text-8xl md:text-[200px] lg:text-[300px] bg-text font-bold">
            Almost!
          </h1>
          <h3 className="text-xl md:text-2xl lg:text-4xl text-center font-bold mb-5 mt-3 md:mt-5">
            Feature coming soon
          </h3>
          <h5 className="text-base md:text-lg text-center font-semibold w-[90%] md:w-[70%] lg:w-1/2 mx-auto">
            The page you entered is not yet available. Stay glued as it will
            soon be added. Thank you for your patience
          </h5>
          <div className="flex w-full justify-center">
            <Link to="/">
              <button className="py-2 px-8 font-semibold bg-green-900 hover:bg-green-700 text-white rounded-3xl mt-10">
                {" "}
                GO TO HOMEPAGE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComingSoon;
