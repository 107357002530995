import sthumb1 from "../assets/images/conference.jpg";
import thumb3 from "../assets/images/beach_cleanup/4.jpg";
import sthumb3 from "../assets/images/blog3.jpg";
import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";
import articleImg from "../assets/images/blog2_articles.jpeg";
import thumb2 from "../assets/images/blog2.jpeg";
import facebook from "../assets/images/facebook_g.svg";
import twitter from "../assets/images/twitter_g.svg";
import instagram from "../assets/images/instagram_g.svg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function Blog2() {
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog"
        canonical="https://www.whroghana.org/blog2"
      />
      <div className="team-display-section py-14 md:py-16 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap box-border md:px-10">
            <div className="w-full xl:w-[70%] xl:px-10 box-border mb-20 xl:mb-0">
              <div className="article">
                <img alt="The Coastal & Marine Resources Conservation" src={thumb2} className="mb-6" />
                <div className="flex items-center my-5">
                  <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                    EVENT
                  </span>
                  <h6 className="ml-5">
                    By <span className="publisher">Dave Adams</span> published{" "}
                    <span className="pub-date">2023</span>
                  </h6>
                </div>
                <h1 className="text-4xl mb-2 font-medium">
                  Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                  Resources Conservation’
                </h1>

                <img alt="" src={articleImg} className="w-full" />
              </div>
              <div className="flex pt-4 gap-x-5 text-green-900 md:mt-10">
                <a href="https://www.facebook.com/whroghana/" target="_blank">
                  <img alt="" className="h-5" src={facebook} />
                </a>
                <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                  <img alt="" className="h-5" src={twitter} />
                </a>
                <a href="https://www.instagram.com/whroghana/" target="_blank">
                  <img alt="" className="h-5" src={instagram} />
                </a>
              </div>
              <div className="comments mt-20">
                <h1 className="text-2xl mb-5">Leave a comment</h1>
                <form>
                  <textarea
                    className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                    placeholder="Comments*"
                  ></textarea>
                  <div className="flex box-border gap-x-10">
                    <div className="w-1/2">
                      <input
                        type="text"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Full Name*"
                      />
                    </div>
                    <div className="w-1/2">
                      <input
                        type="email"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full xl:w-[30%] xl:pr-10 box-border">
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog7">
                  <div className="w-1/4">
                    <img alt="" src={thumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2024</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO Celebrates World Oceans Day 2024 with Awareness
                      Creation and Beach Cleanup
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog5">
                  <div className="w-1/4">
                    <img alt="" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div>
              {/* <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div> */}
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog">
                  <div className="w-1/4">
                    <img alt="" src={sthumb1} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      2nd Ghana National Sea Turtle Conference 2023
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog2;
