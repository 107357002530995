import sthumb1 from "../assets/images/conference.jpg";
import sthumb2 from "../assets/images/blog2.jpeg";
import sthumb3 from "../assets/images/blog3.jpg";
import sthumb4 from "../assets/images/blog4.png";
// import sthumb5 from "../assets/images/blog5.png";

import thumb1 from "../assets/images/blog5.png";
import thumb2 from "../assets/images/mangrove/5.png";
import thumb3 from "../assets/images/beach_cleanup/4.jpg";
// import thumb4 from "../assets/images/blog4-3.jpg";
// import thumb5 from "../assets/images/blog4-4.png";
import facebook from "../assets/images/facebook_g.svg";
import twitter from "../assets/images/twitter_g.svg";
import instagram from "../assets/images/instagram_g.svg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function Blog5() {
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog"
        canonical="https://www.whroghana.org/blog5"
      />
      <div className="team-display-section py-14 md:py-16 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap box-border md:px-10">
            <div className="w-full xl:w-[70%] xl:px-10 box-border mb-20 xl:mb-0">
              <div className="article">
                <img alt="Anyanui Mangrove Market" src={thumb1} className="mb-6" />
                <div className="flex items-center my-5">
                  <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                    MEDIA
                  </span>
                  <h6 className="ml-5">
                    By{" "}
                    <span className="publisher">
                      Patrick Abbeyquaye & Jannatul-Firdaus Alhussein{" "}
                    </span>{" "}
                    published <span className="pub-date">2023</span>
                  </h6>
                </div>
                <h1 className="text-4xl mb-2 font-medium">
                  Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s Visit to
                  Anyanui Mangrove Market
                </h1>

                <p className="article-par">
                  Anyanui, Ghana - On September 9, 2023, a dedicated team from
                  WHRO embarked on an insightful expedition to the Anyanui
                  mangrove market. This visit, a routine endeavor for the
                  organization, aimed to update the team on the intricate
                  dynamics of mangrove harvesting in this ecologically vital
                  region of Ghana&apos;s coastline.
                </p>
                <p className="article-par">
                  Amidst the serene ambiance of Anyanui, our team embarked on an
                  illuminating boat tour to assess the prevailing state of the
                  mangroves. The result was a captivating encounter with all
                  three previously documented species of mangroves in the area,
                  as reported by Nunoo and Agyekumhene in 2023. These species
                  include the white mangroves (Avicennia germinans), red
                  mangroves (Rhizophora racemosa), and black mangroves
                  (Laguncularia racemosa), with the red mangroves asserting
                  their dominance in this coastal ecosystem. Additionally, our
                  team identified the presence of a large expanse of the
                  mangrove associate, Acrostichum aureum, which seem to be
                  taking over the ecosystem at a high rate, probably due to the
                  rampant cutting of mangroves for economic purposes exposing
                  the land and allowing colonization by the fern.
                </p>
                {/* <img alt=" harvesting of mangroves" src={thumb2} className="mb-6" /> */}
                <p className="article-par">
                  A noteworthy observation during our visit was the live
                  harvesting of mangroves by local community members, a practice
                  undertaken routinely due to community members farming mangrove
                  as a livelihoods. This observation highlights the delicate
                  balance between human sustenance and ecological preservation
                  in unique ecosystems such as mangroves.
                </p>
                <p className="article-par">
                  Beyond its ecological significance, the Anyanui mangrove
                  enclave also offers an opportunity for tourists and holds
                  enormous potential for sustainable mangrove ecotourism.
                  Visitors can relish the opportunity to explore interconnecting
                  root systems of the red mangroves, crafting enchanting paths
                  for cruises on the Volta River. Among the avian wonders that
                  grace the area are the Long-tailed cormorant (Microcarbo
                  africanus), Spur-winged lapwing (Vanellus spinosus), Royal
                  terns (Thalasseus maximus), Whimbrel (Numenius phaeopus),
                  Western reef egret (Egretta gularis), Pied Kingfisher (Ceryle
                  rudis), and Little egrets (Egretta garzetta). Moreover,
                  tourists have the unique privilege of observing the
                  traditional fishing practices of local fishermen. These
                  skilled individuals employ various fishing methods, including
                  hand-picking, catch nets, set nets, hook and line, brush park
                  fishing, and traps, among others. This immersive experience
                  provides visitors with a deeper understanding of the rich
                  practices and culture making up the Anyanui community.
                </p>
                <img alt="A picture of the enchanting allure" src={thumb2} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  A picture of the enchanting allure of vibrant mangroves as
                  their canopy stretches elegantly toward the sky
                </p>
                <p className="article-par">
                  WHRO&apos;s recent expedition to the Anyanui mangrove market
                  reaffirms our commitment to preserving Ghana&apos;s coastal
                  treasures through research and information sharing. There is
                  the need for sustainable practices to conserve coastal
                  resources for continued provision of ecosystem services to
                  support socioeconomic development of present and future
                  generation. With the right management practices and
                  approaches, most coastal resources could be developed for
                  their non-consumptive uses.
                </p>
              </div>

              <div className="flex pt-4 gap-x-5 text-green-900 md:mt-10">
                <a href="https://www.facebook.com/whroghana/" target="_blank">
                  <img alt="Facebook icon" className="h-5" src={facebook} />
                </a>
                <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                  <img alt="Twitter icon" className="h-5" src={twitter} />
                </a>
                <a href="https://www.instagram.com/whroghana/" target="_blank">
                  <img alt="Instagram icon" className="h-5" src={instagram} />
                </a>
              </div>
              <div className="comments mt-20">
                <h1 className="text-2xl mb-5">Leave a comment</h1>
                <form>
                  <textarea
                    className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                    placeholder="Comments*"
                  ></textarea>
                  <div className="flex box-border gap-x-10">
                    <div className="w-1/2">
                      <input
                        type="text"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Full Name*"
                      />
                    </div>
                    <div className="w-1/2">
                      <input
                        type="email"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full xl:w-[30%] xl:pr-10 box-border">
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog7">
                  <div className="w-1/4">
                    <img alt="WHRO Celebrates World Oceans Day 2024" src={thumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2024</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO Celebrates World Oceans Day 2024 with Awareness
                      Creation and Beach Cleanup
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="Sea Turtles" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="Sea Turtle Conservation in
                      Ghana" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="The Coastal & Marine Resources Conservation" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog">
                  <div className="w-1/4">
                    <img alt="2nd Ghana National Sea Turtle Conference 2023" src={sthumb1} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      2nd Ghana National Sea Turtle Conference 2023
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog5;
