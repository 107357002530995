import sb from "../assets/images/sb.jpg";
import ysp1 from "../assets/images/ysp1.jpg";
import ysp2 from "../assets/images/ysp2.jpg";
import ysp3 from "../assets/images/ysp3.jpg";
import ed from "../assets/images/ed.png";
import ed2 from "../assets/images/ed2.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function GalleryDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div
        id="gallery"
        className="gallery-section pt-14 md:pt-16 lg:pt-32 overflow-hidden"
      >
        <div className="container">
          <div data-aos="fade-down">
            <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-1"
            >
              OUR GALLERY
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Relive Our Stories
            </h3>
          </div>
          <Link to="/gallery-list">
            <div
              data-aos="zoom-in"
              data-aos-delay="200"
              className="flex md:px-5 flex-container flex-wrap"
            >
              <div className="gallery-img">
                <img alt="community education" src={ed} />
              </div>
              <div className="gallery-img">
                <img alt="shore birds" src={sb} />
              </div>
              <div className="gallery-img">
                <img alt="community education" src={ed2} />
              </div>
              <div className="gallery-img">
                <img alt="young scientist programme" src={ysp1} />
              </div>
              <div className="gallery-img">
                <img alt="young scientist programme" src={ysp2} />
              </div>
              <div className="gallery-img">
                <img alt="young scientist programme" src={ysp3} />
              </div>
            </div>
          </Link>
          <div
            data-aos="fade-up"
            className="flex w-full justify-center md:pt-10"
          >
            <Link to="/gallery-list">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                View Full Gallery
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryDisplay;
