import TeamSlider from "../components/TeamSlider";
import kesse from "../assets/images/team/kesse.jpg";

function TeamProfile10() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={kesse}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Emmanuel Kesse
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              CLIMATE CHANGE OFFICER
            </h5>
            <p className="mb-5 text-justify">
              Emmanuel Kesse Asante holds a Bachelor of Science degree in Marine
              Science from the University of Ghana, Legon, where he graduated on
              top of his class, earning the esteemed Professor Eric Ago Kwei’s
              Prize for the Best Graduating Student in Marine and Fisheries
              Sciences for the 2020/2021 academic year. Following his
              undergraduate studies, he assumed the role of a teaching and
              research assistant at the Department of Marine and Fisheries,
              University of Ghana, for a duration of one year and gained diverse
              experience in natural resource conservation. Kesse further
              enriched his experience by serving as a field assistant with the
              Wildlife and Human Resource Organisation (WHRO), Ghana. In this
              capacity, he contributed to shore bird monitoring and turtle
              nesting assessments along the coast of Ghana. Additionally, Kesse
              actively participated in mangrove restoration initiatives and
              community outreach programs during his time with WHRO Ghana.
            </p>
            <p className="mb-5 text-justify">
              Currently, Kesse is pursuing his doctoral studies at Louisiana
              State University, Baton Rouge, USA. His research is centered on
              biophysical modeling of impacts of climate change and climate
              interventions, such as Solar Radiation Modification, on sea level
              variations, coastal communities, and ecosystems, with a primary
              focus on mangroves. He is actively engaged in collaborative
              research with experts from the National Center for Atmospheric
              Research (NCAR). Kesse possesses proficiency in programming
              languages such as Python, Geographic Information Systems (GIS),
              and Weather Research and Forecasting (WRF) modeling.
            </p>
            <p className="mb-5 text-justify">
              Kesse is volunteering with WHRO and using his immense knowledge
              and skill in climate change to help and advise on developing
              strategies and build capacities for addressing climate change
              impacts and challenges on local communities, especially the youth.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile10;
