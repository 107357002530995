// import pdf from "../assets/images/pdf.png";
import mtsg from "/images/mtsg.jpg";
import degradation from "/images/degradation.jpg";
import guidelines from "/images/guidelines.jpg";
import hawksbill from "/images/hawksbill.jpg";
import illumination from "/images/illumination.jpg";
import kinixys from "/images/kinixys.jpg";
import loggerheads from "/images/loggerheads.jpg";
import mangrove from "/images/mangrove.jpg";
import marine from "/images/marine.jpg";
import muniPomadze from "/images/muni-pomadze.jpg";
import nesting from "/images/nesting.jpg";
import olivacea from "/images/olivacea.jpg";
import perceptions from "/images/perceptions.jpg";
import resilient1 from "/images/resilient-1.jpg";
// import resilient2 from "/images/resilient-2.jpg";
import swot from "/images/swot.jpg";
import tabooRoles from "/images/taboo-roles.jpg";
import taboos from "/images/taboos.jpg";
import triparty from "/images/triparty.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function PubList() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-2 text-white"
              >
                INSIGHTS AND DISCOVERIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Our Research & Publications
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div id="programmes" className="py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
              <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-10 flex-wrap box-border justify-center">
                {/* <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow">
                      <img src={guidelines} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2023</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Guiding Framework to Address Sea Turtle Poaching in a
                          Local Community: A Case Study from Ghana
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={resilient1} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2022</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Resilient lagoons? Climate change, sustainability and
                          adaptation
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div> */}
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={degradation} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2022</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Mangrove Degradation and Management Practices along
                          the Coast of Ghana
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={nesting} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2021</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Sea Turtle Nesting Activity in Ghana, West Africa
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={olivacea} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2021</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Genetic structure of olive ridley sea turtles
                          (Lepidochelys olivacea) in Ghana, West Africa
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={kinixys} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2021</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Kinixys homeana, Home&apos;s Hinge-back Tortoise
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={triparty} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2021</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          A Tri-party Approach Towards Sustainable Plastic Waste
                          Management: A Developing Economy Perspective
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={marine} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2020</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          State of the Marine Environment Report for Ahanta
                          West, Ellembelle, Jomoro, and Nzema East Districts in
                          the Western Region of Ghana
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div> */}
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={illumination} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2020</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Gillnet illumination as an effective measure to reduce
                          sea turtle bycatch
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/10"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={hawksbill} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2020</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Encounter of Hawksbill Sea Turtles in the Coastal
                          Waters of Ghana
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/11"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={mtsg} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2018</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Sea Turtles in the West Africa and East Atlantic
                          Region (MTSG Annual Report 2018)
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/12"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow">
                      <img src={muniPomadze} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2018</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Impacts of lagoon opening and implications for coastal
                          management: case study from Muni-Pomadze lagoon, Ghana
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/13"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={swot} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2017</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          THE SEA TURTLES OF AFRICA
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/14"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={tabooRoles} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2017</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          The Role of Taboos in the Protection and Recovery of
                          Sea Turtles
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/15"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={taboos} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2017</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Traditional Taboos Help Save Ghana&apos;s Sea Turtles
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/16"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={loggerheads} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2015</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Loggerhead Sea Turtle Nesting Activity in Ghana
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/17"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={perceptions} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2014</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Perceptions from Fishing Communities on Sea Turtle
                          Status and Conservation in Central Ghana!
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div data-aos="zoom-in" className="programme-card bg-green-200">
                  <Link
                    to="/publications/18"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="card-shadow h-full">
                      <img src={mangrove} />
                      <div className="px-6 bg-green-200 py-6">
                        <div className="flex items-center mb-2">
                          <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                            PUBLICATION
                          </span>
                          <h6 className="ml-5">2014</h6>
                        </div>
                        <h4 className="text-lg md:text-xl font-semibold">
                          Mangrove Cultivation and Management In Ghana: Issues
                          and Options Report
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PubList;
