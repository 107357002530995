import TeamSlider from "../components/TeamSlider";
import sly from "../assets/images/team/sly.jpg";

function TeamProfile5() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={sly}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Sylvester Ofosu-Gyeabour
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              BOARD MEMBER
            </h5>
            <p className="mb-5 text-justify">
              Sylvester Ofosu-Gyeabour is a registered and licensed dietitian,
              who received his bachelor’s degree in University of Ghana. He was
              awarded a degree in Home Science, now Family and Consumer Science
              in the year 2010. He then furthered his studies to obtain a
              master’s degree in Dietetics from the same institution in 2016. He
              currently is pursuing his PhD in Medical Science with Lincoln
              University, Malaysia.
            </p>
            <p className="mb-5 text-justify">
              With a great passion for teaching, Sylvester has contributed to
              the development of student at Apex College and College Senior High
              School both in Accra. He works as a lecturer at the Nursing
              Department of KAAF University and the dietician at Faith
              Evangelical Hospital, Accra Ghana.
            </p>
            <p className="mb-5 text-justify">
              Sylvester is currently interested in health, safety, and nutrition
              in children. He works with communities to help promote healthy
              eating style and behavior to enhance improve health conditions in
              communities.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile5;
