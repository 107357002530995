import { Link } from "react-router-dom";
// import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import team from "../assets/images/worker1.jpg";
// import team2 from "../assets/images/worker.jpg";
import andrews from "../assets/images/team/andrews.jpg";
import samuel from "../assets/images/team/samuel.jpg";
import delali from "../assets/images/team/delali.jpg";
import stephen from "../assets/images/team/stephen.jpg";
import sly from "../assets/images/team/sly.jpg";
import christina from "../assets/images/team/christina.jpg";
import laila from "../assets/images/team/laila.jpg";
import james from "../assets/images/team/james.jpg";
import jannah from "../assets/images/team/jannah.jpg";
import dede from "../assets/images/team/dede.jpg";
import sam from "../assets/images/team/sam.jpg";
import kesse from "../assets/images/team/kesse.jpg";

function TeamSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="relative pb-6 md:pb-12 box-border">
        <div className="container">
          <div className="slider-container">
            <Slider {...settings}>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/1">
                  <img alt="Dr. Andrews Agyekumhene" className="mx-auto mb-6" src={andrews} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Dr. Andrews Agyekumhene
                  </h6>
                  <h6 className="text-sm">EXECUTIVE DIRECTOR</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/2">
                  <img alt="Samuel Quaatey" className="mx-auto mb-6" src={samuel} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Samuel Quaatey
                  </h6>
                  <h6 className="text-sm">DIRECTOR</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/3">
                  <img alt="Delali Betty Dordzi" className="mx-auto mb-6" src={delali} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Delali Betty Dordzi
                  </h6>
                  <h6 className="text-sm">PROGRAMS MANAGER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/4">
                  <img alt="Stephen Nassam" className="mx-auto mb-6" src={stephen} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Stephen Nassam
                  </h6>
                  <h6 className="text-sm">FINANCE MANAGER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/9">
                  <img alt="Jannatul Firdaus" className="mx-auto mb-6" src={jannah} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Jannatul Firdaus
                  </h6>
                  <h6 className="text-sm">PROGRAMME COORDINATOR</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/11">
                  <img alt="Jennifer Dede Ankrah" className="mx-auto mb-6" src={dede} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Jennifer Dede Ankrah
                  </h6>
                  <h6 className="text-sm">MEDIA & COMMUNICATION OFFICER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/12">
                  <img alt="Sampson Mawuko Kudior" className="mx-auto mb-6" src={sam} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Sampson Mawuko Kudior
                  </h6>
                  <h6 className="text-sm">
                    COMMUNITY ENGAGEMENT & OUTREACH OFFICER
                  </h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/10">
                  <img alt="Emmanuel Kesse" className="mx-auto mb-6" src={kesse} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Emmanuel Kesse
                  </h6>
                  <h6 className="text-sm">CLIMATE CHANGE OFFICER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/5">
                  <img alt="Sylvester Ofosu-Gyeabour" className="mx-auto mb-6" src={sly} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Sylvester Ofosu-Gyeabour
                  </h6>
                  <h6 className="text-sm">BOARD MEMBER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/6">
                  <img alt="Dr. Christina Appiah-Nimo" className="mx-auto mb-6" src={christina} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Dr. Christina Appiah-Nimo
                  </h6>
                  <h6 className="text-sm">BOARD MEMBER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/7">
                  <img alt="Dr. Laila Gifty Akita" className="mx-auto mb-6 w-[440px]" src={laila} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    Dr. Laila Gifty Akita
                  </h6>
                  <h6 className="text-sm">BOARD MEMBER</h6>
                </Link>
              </div>
              <div className="px-5 flex justify-center">
                <Link to="/team-profile/8">
                  <img alt="James Biney" className="mx-auto mb-6" src={james} />
                  <h6 className="text-xl md:text-2xl font-semibold">
                    James Biney
                  </h6>
                  <h6 className="text-sm">BOARD MEMBER</h6>
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
export default TeamSlider;
