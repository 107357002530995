import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import s1 from "../assets/images/support/1.jpg";
import s2 from "../assets/images/support/2.jpg";
import s3 from "../assets/images/support/3.jpg";
import s4 from "../assets/images/support/4.jpg";
import s5 from "../assets/images/support/5.jpg";
import s6 from "../assets/images/support/6.jpg";
import s7 from "../assets/images/support/7.jpg";
import s8 from "../assets/images/support/8.jpg";
import s9 from "../assets/images/support/9.jpg";
import s10 from "../assets/images/support/10.jpg";
import s11 from "../assets/images/support/11.jpg";
import s12 from "../assets/images/support/12.jpg";
import s13 from "../assets/images/support/13.jpg";
import s14 from "../assets/images/support/14.jpg";
import s15 from "../assets/images/support/15.jpg";
import s16 from "../assets/images/support/16.jpg";
import s17 from "../assets/images/support/17.jpg";
import s18 from "../assets/images/support/18.jpg";
import s19 from "../assets/images/support/19.jpg";
import s20 from "../assets/images/support/20.jpg";
import s21 from "../assets/images/support/21.jpg";
import s22 from "../assets/images/support/22.jpg";
import s26 from "../assets/images/support/26.jpg";
import s27 from "../assets/images/support/27.jpg";
import s28 from "../assets/images/support/28.jpg";
import s29 from "../assets/images/support/29.jpg";
import s31 from "../assets/images/support/31.jpg";
import s32 from "../assets/images/support/32.jpg";
import s33 from "../assets/images/support/33.jpg";
import s34 from "../assets/images/support/34.jpg";
import s35 from "../assets/images/support/35.jpg";
import s36 from "../assets/images/support/36.jpg";
import s37 from "../assets/images/support/37.jpg";
import s38 from "../assets/images/support/38.jpg";
import s39 from "../assets/images/support/39.jpg";
import s40 from "../assets/images/support/40.jpg";
import s41 from "../assets/images/support/41.jpg";
import s42 from "../assets/images/support/42.jpg";
import MetaTags from "../components/MetaTags";

// Define the array of images here
const photos = [
  { src: s1, width: 1000, height: 667 },
  { src: s2, width: 1000, height: 667 },
  { src: s3, width: 1000, height: 667 },
  { src: s4, width: 1000, height: 667 },
  { src: s5, width: 1000, height: 667 },
  { src: s6, width: 1000, height: 667 },
  { src: s7, width: 1000, height: 667 },
  { src: s8, width: 1000, height: 667 },
  { src: s9, width: 1000, height: 667 },
  { src: s10, width: 1000, height: 667 },
  { src: s11, width: 1000, height: 667 },
  { src: s12, width: 1000, height: 667 },
  { src: s13, width: 1000, height: 667 },
  { src: s14, width: 1000, height: 667 },
  { src: s15, width: 1000, height: 667 },
  { src: s16, width: 1000, height: 667 },
  { src: s17, width: 1000, height: 667 },
  { src: s18, width: 1000, height: 667 },
  { src: s19, width: 1000, height: 667 },
  { src: s20, width: 1000, height: 667 },
  { src: s21, width: 1000, height: 667 },
  { src: s22, width: 1000, height: 667 },
  { src: s26, width: 1000, height: 667 },
  { src: s27, width: 1000, height: 667 },
  { src: s28, width: 1000, height: 667 },
  { src: s29, width: 1000, height: 667 },
  { src: s31, width: 1000, height: 667 },
  { src: s32, width: 1000, height: 667 },
  { src: s33, width: 1000, height: 667 },
  { src: s34, width: 1000, height: 667 },
  { src: s35, width: 1000, height: 667 },
  { src: s36, width: 1000, height: 667 },
  { src: s37, width: 1000, height: 667 },
  { src: s38, width: 1000, height: 667 },
  { src: s39, width: 1000, height: 667 },
  { src: s40, width: 1000, height: 667 },
  { src: s41, width: 1000, height: 667 },
  { src: s42, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function CommunitySupportGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <MetaTags
        title="Gallery Page - WHRO"
        keywords="Gallery, Community Support, Mothers, Children"
        canonical="https://www.whroghana.org/community-support-gallery"
      />
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Community Support
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
