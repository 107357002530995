import { Link } from "react-router-dom";
import TeamSlider from "./TeamSlider";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function TeamDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div className="team-display-section mt-14 md:mt-16 lg:mt-32 bg-green-200 py-14 pb-32 md:pt-16 lg:py-32">
        <div className="container">
          <div data-aos="fade-down">
            <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-1"
            >
              OUR TEAM
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Those Who Make It Possible
            </h3>
          </div>
          <TeamSlider />
          <div data-aos="fade-up" className="flex w-full justify-center">
            <Link to="/team">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                View Full Team
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="md:px-9">
            <div
              data-aos="zoom-in-up"
              data-aos-duration="400"
              data-aos-delay="50"
              className="bg-blue-black -mt-20 p-6
             md:py-10 md:px-14"
            >
              <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 lg:w-3/4">
                  {/* <h6 className="text-xs md:text-sm mini-heading mb-14 text-white">
                    GET INVOLVED
                  </h6> */}
                  <h6 className="main-heading font-medium text-2xl lg:text-4xl mb-5 md:mb-0 donation-text">
                    Find out how you can help the environment
                  </h6>
                </div>
                <div className="w-full mt-4 md:mt-0 md:w-1/2 lg:w-1/4 flex justify-center md:justify-end">
                  <Link to={"/volunteer"}>
                    <button className="px-10 py-3 bg-white rounded-3xl hover:bg-green-200 hover:text-blue-black">
                      JOIN US
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamDisplay;
