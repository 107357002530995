import TeamSlider from "../components/TeamSlider";
import delali from "../assets/images/team/delali.jpg";

function TeamProfile3() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={delali}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Delali Betty Dordzi
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              PROGRAMS MANAGER
            </h5>
            <p className="mb-5 text-justify">
              Betty Delali Dordzi holds a BSc degree in Entomology and Wildlife
              from University of Cape Coast. She has interned with the Wildlife
              Division of the Forestry Commission for close to 3 years where she
              gained practical and in-depth experience in wetland and coastal
              zone management issues with special focus on community engagement
              and stakeholder management.
            </p>
            <p className="mb-5 text-justify">
              Betty has trained in and has skills in Monitoring and Evaluation
              (M&E) of projects and years of practical experience in Project
              Management. She has conducted training for community groups in
              wetland resources management. She has assisted with biodiversity
              assessment for big companies resulting in development of EIAs for
              projects such as the Tema Port Expansion project, Eni Ghana
              Onshore Receiving Facility operation phase, Dualization of the
              Ejisu-Konongo highway, and development of the Takoradi-Huni valley
              railway.
            </p>
            <p className="mb-5 text-justify">
              Betty has attended and presented papers at conferences. Betty has
              been involved in restoration and livelihood support programmes for
              local communities which has resulted in promotion of wildlife and
              their habitats. She has also facilitated training for local
              communities in diverse income generating livelihoods, which led to
              the protection of natural resources through reduced pressures and
              drivers. She specializes in Project Management and capacity
              building.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile3;
