import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import environmental1 from "../assets/images/environmental/1.png";
import environmental2 from "../assets/images/environmental/2.png";
import environmental3 from "../assets/images/environmental/3.png";
import environmental4 from "../assets/images/environmental/4.png";
import environmental5 from "../assets/images/environmental/5.png";
import environmental6 from "../assets/images/environmental/6.png";
import environmental7 from "../assets/images/environmental/7.png";
import environmental8 from "../assets/images/environmental/8.png";
import MetaTags from "../components/MetaTags";

// Define the array of images here
const photos = [
  { src: environmental1, width: 1000, height: 667 },
  { src: environmental2, width: 1000, height: 667 },
  { src: environmental3, width: 1000, height: 667 },
  { src: environmental4, width: 1000, height: 667 },
  { src: environmental5, width: 1000, height: 667 },
  { src: environmental6, width: 1000, height: 667 },
  { src: environmental7, width: 1000, height: 667 },
  { src: environmental8, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function EMonitoringGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <MetaTags
        title="Gallery Page - WHRO"
        keywords="Environmental Monitoring"
        canonical="https://www.whroghana.org/e-monitoring-gallery"
      />
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Environmental Monitoring
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
