// import turtles from "../assets/images/turtles.jpg";
// import internship from "../assets/images/internship2.png";
// import ysp from "../assets/images/ysp.jpg";
// import m from "../assets/images/mangrove/7.png";
// import s from "../assets/images/com_support.jpg";
// import ed1 from "../assets/images/eaa/19.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ProgramSlider from "./ProgramSlider";

function ProgrammesDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div
        id="programmes"
        className="py-14 md:py-16 lg:py-32 bg-green-200 mt-14 md:mt-16 lg:mt-32"
      >
        <div className="container">
          <div data-aos="fade-down">
            <h6 className="text-center text-xs md:text-sm mini-heading mb-1">
              WHAT WE RUN
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Our Programmes
            </h3>
          </div>
          {/* <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-10 flex-wrap box-border justify-center">
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/sea-turtles-programme"}>
                  <img src={turtles} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Sea Turtles Conservation Programme
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/ysp"}>
                  <img src={ysp} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Young Scientists Programme
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/internship-programme"}>
                  <img src={internship} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Volunteer & Internship Progammes
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/mangrove-conservation"}>
                  <img src={m} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Mangrove Conservation
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/community-programme"}>
                  <img src={s} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Community Support
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="programme-card px-4 hover:text-green-900">
                <Link to={"/education-gallery"}>
                  <img src={ed1} className="w-full" />
                  <div className="py-5">
                    <h4 className="text-lg md:text-xl font-semibold">
                      Education and Awareness
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div> */}

          <ProgramSlider />
          <div className="flex justify-center">
            <Link to="/programmes">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                View All Our Programmes
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgrammesDisplay;
