import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { Outlet, ScrollRestoration } from "react-router-dom";

function Root() {
  return (
    <>
      <Nav />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </>
  );
}

export default Root;
