import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import ab1 from "../assets/images/sb/1.jpg";
import ab2 from "../assets/images/sb/2.jpg";
import ab3 from "../assets/images/sb/3.jpg";
import ab4 from "../assets/images/sb/4.jpg";
import ab5 from "../assets/images/sb/5.jpg";
import ab6 from "../assets/images/sb/6.jpg";
import ab7 from "../assets/images/sb/7.jpg";
import ab8 from "../assets/images/sb/8.jpg";
import ab9 from "../assets/images/sb/9.jpg";
import ab10 from "../assets/images/sb/10.jpg";
import ab11 from "../assets/images/sb/11.jpg";
import ab12 from "../assets/images/sb/12.jpg";
import ab13 from "../assets/images/sb/13.jpg";
import ab14 from "../assets/images/sb/14.jpg";
import ab15 from "../assets/images/sb/15.jpg";
import ab16 from "../assets/images/sb/16.jpg";
import ab17 from "../assets/images/sb/17.jpg";
import ab18 from "../assets/images/sb/18.jpg";
import ab19 from "../assets/images/sb/19.jpg";
import ab20 from "../assets/images/sb/20.jpg";
import ab21 from "../assets/images/sb/21.jpg";
import ab22 from "../assets/images/sb/22.jpg";
import ab23 from "../assets/images/sb/23.jpg";
import ab24 from "../assets/images/sb/24.jpg";
import ab25 from "../assets/images/sb/25.jpg";
import ab26 from "../assets/images/sb/26.jpg";
import ab27 from "../assets/images/sb/27.jpg";
import ab28 from "../assets/images/sb/28.jpg";
import ab29 from "../assets/images/sb/29.jpg";
import ab30 from "../assets/images/sb/30.jpg";
import ab31 from "../assets/images/sb/31.jpg";
import ab32 from "../assets/images/sb/32.jpg";
import ab33 from "../assets/images/sb/33.jpg";
import ab34 from "../assets/images/sb/34.jpg";

// Define the array of images here
const photos = [
  { src: ab1, width: 1000, height: 667 },
  { src: ab2, width: 1000, height: 667 },
  { src: ab3, width: 1000, height: 667 },
  { src: ab4, width: 1000, height: 667 },
  { src: ab5, width: 1000, height: 667 },
  { src: ab6, width: 1000, height: 667 },
  { src: ab7, width: 1000, height: 667 },
  { src: ab8, width: 1000, height: 667 },
  { src: ab9, width: 1000, height: 667 },
  { src: ab10, width: 1000, height: 667 },
  { src: ab11, width: 1000, height: 667 },
  { src: ab12, width: 1000, height: 667 },
  { src: ab13, width: 1000, height: 667 },
  { src: ab14, width: 1000, height: 667 },
  { src: ab15, width: 1000, height: 667 },
  { src: ab16, width: 1000, height: 667 },
  { src: ab17, width: 1000, height: 667 },
  { src: ab18, width: 1000, height: 667 },
  { src: ab19, width: 1000, height: 667 },
  { src: ab20, width: 1000, height: 667 },
  { src: ab21, width: 1000, height: 667 },
  { src: ab22, width: 1000, height: 667 },
  { src: ab23, width: 1000, height: 667 },
  { src: ab24, width: 1000, height: 667 },
  { src: ab25, width: 1000, height: 667 },
  { src: ab26, width: 1000, height: 667 },
  { src: ab27, width: 1000, height: 667 },
  { src: ab28, width: 1000, height: 667 },
  { src: ab29, width: 1000, height: 667 },
  { src: ab30, width: 1000, height: 667 },
  { src: ab31, width: 1000, height: 667 },
  { src: ab32, width: 1000, height: 667 },
  { src: ab33, width: 1000, height: 667 },
  { src: ab34, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function ShoreBirdsGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Aquatic Birds
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
