import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import ed01 from "../assets/images/eaa/01.jpg";
import ed02 from "../assets/images/eaa/02.jpg";
import ed03 from "../assets/images/eaa/03.jpg";
import ed04 from "../assets/images/eaa/04.jpg";
import ed05 from "../assets/images/eaa/05.jpg";
import ed06 from "../assets/images/eaa/06.jpg";
import ed1 from "../assets/images/eaa/1.jpg";
import ed2 from "../assets/images/eaa/2.jpg";
import ed3 from "../assets/images/eaa/3.jpg";
import ed4 from "../assets/images/eaa/4.jpg";
import ed5 from "../assets/images/eaa/5.jpg";
import ed6 from "../assets/images/eaa/6.jpg";
import ed7 from "../assets/images/eaa/7.jpg";
import ed8 from "../assets/images/eaa/8.jpg";
import ed9 from "../assets/images/eaa/9.jpg";
import ed10 from "../assets/images/eaa/10.jpg";
import ed11 from "../assets/images/eaa/11.jpg";
import ed12 from "../assets/images/eaa/12.jpg";
import ed13 from "../assets/images/eaa/13.jpg";
import ed14 from "../assets/images/eaa/14.jpg";
import ed15 from "../assets/images/eaa/15.jpg";
import ed16 from "../assets/images/eaa/16.jpg";
import ed17 from "../assets/images/eaa/17.jpg";
import ed18 from "../assets/images/eaa/18.jpg";
import ed19 from "../assets/images/eaa/19.jpg";
import ed20 from "../assets/images/eaa/20.jpg";
import ed21 from "../assets/images/eaa/21.jpg";
import ed22 from "../assets/images/eaa/22.jpg";
import ed23 from "../assets/images/eaa/23.jpg";
import ed24 from "../assets/images/eaa/24.jpg";
import ed25 from "../assets/images/eaa/25.jpg";
import ed26 from "../assets/images/eaa/26.jpg";
import ed27 from "../assets/images/eaa/27.jpg";
import ed28 from "../assets/images/eaa/28.jpg";
import ed29 from "../assets/images/eaa/29.jpg";
import ed30 from "../assets/images/eaa/30.jpg";
import ed31 from "../assets/images/eaa/31.jpg";
import ed32 from "../assets/images/eaa/32.jpg";
import ed33 from "../assets/images/eaa/33.jpg";
import ed34 from "../assets/images/eaa/34.jpg";
import ed35 from "../assets/images/eaa/35.jpg";
import ed36 from "../assets/images/eaa/36.jpg";
import ed37 from "../assets/images/eaa/37.jpg";
import ed38 from "../assets/images/eaa/38.jpg";
import ed39 from "../assets/images/eaa/39.png";
import ed40 from "../assets/images/eaa/40.png";
import ed41 from "../assets/images/eaa/41.png";
import ed42 from "../assets/images/eaa/42.png";
import ed43 from "../assets/images/eaa/43.png";
import MetaTags from "../components/MetaTags";

// Define the array of images here
const photos = [
  { src: ed01, width: 1000, height: 667 },
  { src: ed02, width: 1000, height: 667 },
  { src: ed03, width: 1000, height: 667 },
  { src: ed04, width: 1000, height: 667 },
  { src: ed05, width: 1000, height: 667 },
  { src: ed06, width: 1000, height: 667 },
  { src: ed1, width: 1000, height: 667 },
  { src: ed2, width: 1000, height: 667 },
  { src: ed3, width: 1000, height: 667 },
  { src: ed4, width: 1000, height: 667 },
  { src: ed5, width: 1000, height: 667 },
  { src: ed6, width: 1000, height: 667 },
  { src: ed7, width: 1000, height: 667 },
  { src: ed8, width: 1000, height: 667 },
  { src: ed9, width: 1000, height: 667 },
  { src: ed10, width: 1000, height: 667 },
  { src: ed11, width: 1000, height: 667 },
  { src: ed12, width: 1000, height: 667 },
  { src: ed13, width: 1000, height: 667 },
  { src: ed14, width: 1000, height: 667 },
  { src: ed15, width: 1000, height: 667 },
  { src: ed16, width: 1000, height: 667 },
  { src: ed17, width: 1000, height: 667 },
  { src: ed18, width: 1000, height: 667 },
  { src: ed19, width: 1000, height: 667 },
  { src: ed20, width: 1000, height: 667 },
  { src: ed21, width: 1000, height: 667 },
  { src: ed22, width: 1000, height: 667 },
  { src: ed23, width: 1000, height: 667 },
  { src: ed24, width: 1000, height: 667 },
  { src: ed25, width: 1000, height: 667 },
  { src: ed26, width: 1000, height: 667 },
  { src: ed27, width: 1000, height: 667 },
  { src: ed28, width: 1000, height: 667 },
  { src: ed29, width: 1000, height: 667 },
  { src: ed30, width: 1000, height: 667 },
  { src: ed31, width: 1000, height: 667 },
  { src: ed32, width: 1000, height: 667 },
  { src: ed33, width: 1000, height: 667 },
  { src: ed34, width: 1000, height: 667 },
  { src: ed35, width: 1000, height: 667 },
  { src: ed36, width: 1000, height: 667 },
  { src: ed37, width: 1000, height: 667 },
  { src: ed38, width: 1000, height: 667 },
  { src: ed39, width: 1000, height: 667 },
  { src: ed40, width: 1000, height: 667 },
  { src: ed41, width: 1000, height: 667 },
  { src: ed42, width: 1000, height: 667 },
  { src: ed43, width: 1000, height: 667 },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

export default function EducationGallery() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <>
      <MetaTags
        title="Gallery Page - WHRO"
        keywords="Gallery, Schools, Education and Awareness, Children"
        canonical="https://www.whroghana.org/education-gallery"
      />
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6 className="text-center text-xs md:text-sm mini-heading mb-1 text-white">
                RELIVE OUR STORIES
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Education and Awareness
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section bg- py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="lg:px-32">
            <Gallery
              images={photos}
              onClick={(index) => handleClick(index)}
              enableImageSelection={false}
            />
            {/* Lightbox component */}
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
