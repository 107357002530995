import { useState } from "react";
import PropTypes from "prop-types";

function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="border-b border-gray-300 rounded-md">
        {/* Accordion Header */}
        <div
          className="flex items-center justify-between px-4 py-2 cursor-pointer"
          onClick={toggleAccordion}
        >
          <h2 className="text-lg md:text-2xl font-semibold">{title}</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-6 w-6 transition-transform transform ${
              isOpen ? "rotate-180" : ""
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={isOpen ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
            />
          </svg>
        </div>

        {/* Accordion Content */}
        {isOpen && (
          <div className="px-4 py-2">
            <p className="text-gray-700">{content}</p>
          </div>
        )}
      </div>
    </>
  );
}

Accordion.PropTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

Accordion.defaultTypes = {
  title: "Title",
  content: "Content",
};

export default Accordion;
