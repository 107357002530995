import sthumb1 from "../assets/images/conference.jpg";
import sthumb2 from "../assets/images/blog2.jpeg";
import sthumb3 from "../assets/images/blog3.jpg";
// import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";

import thumb1 from "../assets/images/blog4.png";
import thumb3 from "../assets/images/beach_cleanup/4.jpg";
import thumb4 from "../assets/images/blog4-3.jpg";
import thumb5 from "../assets/images/blog4-4.png";
import facebook from "../assets/images/facebook_g.svg";
import twitter from "../assets/images/twitter_g.svg";
import instagram from "../assets/images/instagram_g.svg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function Blog3() {
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog"
        canonical="https://www.whroghana.org/blog4"
      />
      <div className="team-display-section py-14 md:py-16 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap box-border md:px-10">
            <div className="w-full xl:w-[70%] xl:px-10 box-border mb-20 xl:mb-0">
              <div className="article">
                <img alt="Sea Turtles" src={thumb1} className="mb-6" />
                <div className="flex items-center my-5">
                  <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                    MEDIA
                  </span>
                  <h6 className="ml-5">
                    By{" "}
                    <span className="publisher">
                      Patrick Abbeyquaye & Jannatul-Firdaus Alhussein
                    </span>{" "}
                    published <span className="pub-date">2023</span>
                  </h6>
                </div>
                <h1 className="text-4xl mb-2 font-medium">
                  WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                  Turtles
                </h1>

                <p className="article-par">
                  Ghana&apos;s coastline has been the site of an ongoing battle
                  for the preservation of sea turtle population, and the
                  Wildlife and Human Resources Organization (WHRO) is at the
                  forefront of this crucial conservation effort. On September 9,
                  2023, a team from WHRO, led by Dr. Andrews Agyekumhene,
                  embarked on a mission that underscores their unwavering
                  commitment to safeguarding these magnificent creatures. This
                  mission was carried out together with WHRO volunteer Patrick
                  Abbeyquaye (Volunteer), Delali Betty Dordzi (Projects Manager,
                  WHRO), and Jannatul-Firdaus Alhussein (Young Scientist
                  Coordinator, WHRO), and centered on equipping the local patrol
                  team in the coastal communities of Angloga in the Volta Region
                  of Ghana and Mankoadze in the Central Region of Ghana with
                  patrol techniques as the sea turtle nesting season began.
                </p>
                <p className="article-par">
                  The urgency of protecting this natural wonder cannot be
                  overstated, and data collection forms the bedrock of informed
                  conservation efforts. Dr. Agyekumhene meticulously reviewed
                  data sheets to ensure comprehensive and precise gathering of
                  data during patrols. Strategies and logistics for effective
                  nesting patrols were also discussed with the patrol team who
                  undertake nightly surveys from 9:00 pm to 5:00 am during the
                  nesting season (September to February). For Mankoadze however,
                  the team survey the beaches throughout the year.
                </p>
                <p className="article-par">
                  Sea turtles, particularly the leatherback, often referred to
                  as gentle giants, have returned to Ghana&apos;s shores to lay
                  their precious eggs. The urgency of protecting this natural
                  wonder cannot be overstated, and data collection forms the
                  bedrock of informed conservation efforts. Dr. Agyekumhene
                  meticulously reviewed data sheets to ensure comprehensive and
                  precise gathering of data during patrols. Strategies and
                  logistics for effective nesting patrols were also discussed
                  with the patrol team who undertake nightly surveys from 9:00
                  pm to 5:00 am during the nesting season (September to
                  February). For Mankoadze however, the team survey the beaches
                  throughout the year.
                </p>
                <p className="article-par">
                  The aim of this mission extended beyond patrolling. It sought
                  to retrain the local team on data sheet usage, raise awareness
                  among community members about sea turtles, and provide
                  practical field experience to dedicated volunteers.
                </p>
                <p className="article-par">
                  On Saturday, September 9, 2023, the WHRO team joined the local
                  patrol team to undertake come patrols. Not long into the
                  patrols, the team was met with disheartening signs of poaching
                  activities carried out by some members of the community.
                  Tragically, three Olive Ridley turtles fell victim to these
                  poachers as The animals were picked up while digging their egg
                  chambers. Heartbreakingly, one of the turtles had already
                  started laying the eggs , but both the mother and her precious
                  eggs were taken by the perpetrators. Evidence of sea turtle
                  tracks indicated that two other turtles had ventured ashore
                  but were frightened by the presence of danger (humans) and
                  returned to sea without laying eggs, a phenomenon known as
                  non-nesting emergence.
                </p>
                {/* <h5 className="text-2xl mb-6 font-medium">
                  Dr. Andrews Agyehumhene&apos;s Insights
                </h5> */}
                <img alt=" Track marks of an Olive Ridley turtle (Lepidochelys olivacea)
                  at Anglo beach" src={thumb4} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  Track marks of an Olive Ridley turtle (Lepidochelys olivacea)
                  at Anglo beach
                </p>
                <p className="article-par">
                  Despite this setback, the WHRO team continued with the patrols
                  and managed to save an Olive Ridley turtle (Lepidochelys
                  olivacea) that was in the process of laying its eggs when they
                  first encountered it. Demonstrating unwavering dedication, the
                  team patiently waited for the turtle to complete the nesting
                  process and returned to safely to sea before the team left.
                  What is truly remarkable about this story is that during the
                  data collection process, the team was approached by potential
                  poachers seeking to exploit these magnificent creatures. In a
                  display of commitment and compassion, the WHRO team took the
                  initiative to educate the poachers about the importance of
                  protecting sea turtles to their livelihoods (fishing). They
                  invited them to join the ongoing campaign to safeguard sea
                  turtle populations, fostering a sense of shared responsibility
                  for the cause.
                </p>
                <p className="article-par">
                  The patrol team will continue their vigilant efforts,
                  conducting nightly surveys throughout the nesting season.
                  Their mission is two-fold: to gather essential data on these
                  ancient mariners and to rescue as many sea turtles as possible
                  from the clutches of poachers while creating awareness towards
                  attitudinal change among the communities. This relentless
                  dedication ensures that sea turtles in Ghana have a fighting
                  chance to thrive, preserving the rich biodiversity of the
                  marine ecosystem.
                </p>
                {/* <h5 className="text-2xl mb-6 font-medium">
                  Ms. Betty Delali Dordzi&apos;s Insights
                </h5> */}
                <img alt="Dr. Andrews Agyekumhene and Jannatul-Firdaus Alhussein with an
                  Olive ridley turtle" src={thumb5} className="mb-1" />
                <p className="italic text-gray-500 text-sm mb-6">
                  Dr. Andrews Agyekumhene and Jannatul-Firdaus Alhussein with an
                  Olive ridley turtle (Lepidochelys olivacea) at Angloga beach
                </p>
                <p className="article-par">
                  WHRO&apos;s commitment to sea turtle conservation in Ghana
                  extends far beyond this singular endeavor. The organization
                  conducts regular visits to various project sites, engaging
                  with local communities and schools to foster awareness and
                  support for this vital cause. These ongoing efforts are part
                  of a broader campaign to protect and conserve sea turtles
                  along the Ghanaian coast, a commitment that WHRO upholds
                  unwaveringly.
                </p>
                <p className="article-par">
                  As we reflect on WHRO&apos;s tireless dedication to preserving
                  Ghana&apos;s sea turtles, we are reminded of the importance of
                  international collaboration in safeguarding our natural
                  heritage. WHRO&apos;s exemplary work serves as an inspiration
                  to all who value the rich value of life that our planet has to
                  offer and underscores the shared responsibility, we hold in
                  preserving it for generations to come.
                </p>
              </div>
              <div className="flex pt-4 gap-x-5 text-green-900 md:mt-10">
                <a href="https://www.facebook.com/whroghana/" target="_blank">
                  <img alt="Facebook icon" className="h-5" src={facebook} />
                </a>
                <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                  <img alt="Twitter icon" className="h-5" src={twitter} />
                </a>
                <a href="https://www.instagram.com/whroghana/" target="_blank">
                  <img alt="Instagram icon" className="h-5" src={instagram} />
                </a>
              </div>
              <div className="comments mt-20">
                <h1 className="text-2xl mb-5">Leave a comment</h1>
                <form>
                  <textarea
                    className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                    placeholder="Comments*"
                  ></textarea>
                  <div className="flex box-border gap-x-10">
                    <div className="w-1/2">
                      <input
                        type="text"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Full Name*"
                      />
                    </div>
                    <div className="w-1/2">
                      <input
                        type="email"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full xl:w-[30%] xl:pr-10 box-border">
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog7">
                  <div className="w-1/4">
                    <img alt="WHRO Celebrates World Oceans Day 2024" src={thumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2024</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO Celebrates World Oceans Day 2024 with Awareness
                      Creation and Beach Cleanup
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog5">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div>
              {/* <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="Sea Turtles" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div> */}
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="Sea Turtle Conservation in
                      Ghana" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="Ms. Delali Betty Dordzi Speaks" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog">
                  <div className="w-1/4">
                    <img alt="2nd Ghana National Sea Turtle Conference 2023" src={sthumb1} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      2nd Ghana National Sea Turtle Conference 2023
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog3;
