import TestimonialSlider from "./TestimonialSlider";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function TestimonialDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div className="team-display-section pt-14 md:pt-16 lg:pt-32">
        <div className="container">
          <div data-aos="fade-down">
            <h6
              className="text-center 
 text-xs md:text-sm mini-heading mb-1"
            >
              TESTIMONIALS
            </h6>
            <h3
              data-aos="fade-up"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              What People Think About Us
            </h3>
          </div>
          <TestimonialSlider />
        </div>
      </div>
    </>
  );
}

export default TestimonialDisplay;
