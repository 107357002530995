import TeamSlider from "../components/TeamSlider";
import dede from "../assets/images/team/dede.jpg";

function TeamProfile11() {
  return (
    <>
      <div className="team-display-section bg-green-200 py-14 md:py-16 lg:py-32">
        <div className="container">
          <div className="ll:px-32">
            <div className="p-5">
              <img
                className="rounded-lg lg:mb-2 w-2/5 mr-14 float-left mb-5"
                src={dede}
              ></img>
            </div>
            <h3 className="text-2xl md:text-3xl font-semibold mb-1 text-green-900">
              Jennifer Dede Ankrah
            </h3>
            <h5 className="text-sm md:text-md font-semibold mb-5">
              MEDIA & COMMUNICATION OFFICER
            </h5>
            <p className="mb-5 text-justify">
              Jennifer Dede Ankrah is currently pursuing a BSc Fisheries Science
              degree at the Department of Marine and Fisheries Sciences,
              University of Ghana. She has extensive knowledge in fisheries with
              her current research Investigating Parasitic Infestation of Pond
              Cultured Nile Tilapia in the Greater Accra Region of Ghana. She
              also has extensive skills in communication and has been involved
              with creating awareness and conducting education programmes on
              environmental issues in several communities where she focuses
              mainly on the youth and female groups. Jennifer has also engaged
              school children on many platforms creating awareness using
              different interactive methods. She has also been active in
              undertaking community support projects and providing livelihood
              support for communities.
            </p>
            <p className="mb-5 text-justify">
              She likes drawing/art, reading, developing children’s programs,
              and going on nature tours.
            </p>
          </div>

          <div className="pt-14 md:pt-16 lg:pt-32 clear-both">
            <TeamSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamProfile11;
