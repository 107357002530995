import React from "react";
import Slider from "react-slick";
import chec from "../assets/images/chec.jpg";
import eni from "../assets/images/eni.jpg";
import esl from "../assets/images/esl.jpg";
import mba from "../assets/images/mba.jpg";
import mps from "../assets/images/mps.jpg";
import turtle from "../assets/images/turtle.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LogoSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        id="clients"
        className="logos-bg lg:-mt-24 ll:-mt-28 relative pt-14 md:pt-16 lg:pt-32 pb-12 ll:bg-cover"
      >
        <div className="container">
          <p className="text-green-800 text-sm pl-5 mb-3">
            *Experts from WHRO have undertaken various environmental assessment
            projects for the following institutions
          </p>
          <div className="slider-container">
            <Slider {...settings}>
              <div className="flex justify-center">
                <img alt="china harbour engineering company logo" className="w-20 md:w-28 mx-auto" src={chec} />
              </div>
              <div className="flex justify-center">
                <img alt="eni logo" className="w-20 md:w-28 mx-auto" src={eni} />
              </div>
              <div className="flex justify-center">
                <img alt="esl logo" className="w-20 md:w-28 mx-auto" src={esl} />
              </div>
              <div className="flex justify-center">
                <img alt="mba logo" className="w-20 md:w-28 mx-auto" src={mba} />
              </div>
              <div className="flex justify-center">
                <img alt="mps logo" className="w-20 md:w-28 mx-auto" src={mps} />
              </div>
              <div className="flex justify-center">
                <img alt="turtle logo" className="w-20 md:w-28 mx-auto" src={turtle} />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
export default LogoSlider;
