import TeamGrid from "../components/TeamGrid";

function Volunteer() {
  return (
    <>
      <div className="header-img md:h-96 z-0">
        <div className="container">
          <div className="px-4 md:px-9">
            <div className="w-full h-full pt-36">
              <h6
                className="text-center 
 text-xs md:text-sm mini-heading mb-1 text-white"
              >
                HELP US
              </h6>
              <h3 className="text-center text-white main-heading font-medium text-2xl md:text-4xl mb-14">
                Become a Volunteer
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="team-display-section pb-14 md:pb-16 lg:pb-32">
        <div className="container">
          <div className="-mt-16 z-10 bg-green-200 mx-2 lp:mx-9 flex shadow-lg rounded-md">
            <div className="w-0 lg:w-1/2 ll:w-3/5 form-img bg-black"></div>
            <div className="lg:w-1/2 ll:w-2/5 py-16 px-5 md:px-20">
              <h3 className="text-center main-heading font-medium text-xl md:text-2xl mb-14">
                Register Here
              </h3>
              <form>
                <input
                  type="text"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                  placeholder="Full Name*"
                ></input>
                <input
                  type="email"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-5"
                  placeholder="Email*"
                ></input>
                <input
                  type="tel"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-5"
                  placeholder="Phone Number*"
                ></input>
                <input
                  type="text"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-5"
                  placeholder="Address*"
                ></input>
                <input
                  type="date"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-5"
                  placeholder="Date Of Birth"
                ></input>
                <input
                  type="text"
                  className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-5"
                  placeholder="Occupation"
                ></input>
                <button
                  type="submit"
                  className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                >
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Volunteer;
